import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Map } from "immutable";
import { useIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import withReducers from "Hocs/withReducers";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import {
  deleteAccess,
  editAccess,
  getAccesses,
  updateAccess
} from "Reducers/project/access";
import { environmentsSelector } from "Reducers/environment";
import {
  addInvitation,
  deleteInvitation,
  editInvitation,
  getInvitations
} from "Reducers/invitation";

import AccessibleTooltip from "Components/AccessibleTooltip";
import ButtonAdd from "Components/ButtonAdd";
import Error from "Components/Error";
import Loading from "Components/Loading";
import PageMeta from "Components/PageMeta";
import PageDescription from "Components/PageDescription";
import SettingLine from "Components/SettingLine";
import UserIcon from "Components/icons/UserIcon";

import LockIcon from "Icons/LockIcon";

import EnvironmentAccessListFields from "../EnvironmentAccessListFields";
import User from "../../../../components/User";
import ModalConfirmDelete from "Components/ModalConfirmDelete";

import InvitationAlert from "../../../../../../common/components/InvitationAlert";
import InvitationLine from "../../../../../../common/components/InvitationLine";
import InvitationResendModal from "../../../../../../common/components/InvitationResendModal";
import InvitationRevokeModal from "../../../../../../common/components/InvitationRevokeModal";

import * as S from "./styles";

const ProjectAccessList = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { organizationId, projectId } = useParams();

  const [invitAlert, setInvitAlert] = useState({ isOpen: false });
  const [modal, setModal] = useState({ isOpen: false });

  const projectAccesses = useSelector(state =>
    state.projectAccess?.getIn(["data", organizationId, projectId], Map())
  );
  const editedAccess = useSelector(state => state.projectAccess?.get("edited"));
  const isLoading = useSelector(state => state.projectAccess?.get("loading"));
  const statusAccess = useSelector(state => state.projectAccess?.get("status"));
  const errorsAccess = useSelector(state => state.projectAccess?.get("errors"));

  const editedInvitation = useSelector(state =>
    state.invitation?.get("edited")
  );
  const invitations = useSelector(state =>
    state.invitation?.getIn(["data", organizationId, projectId], Map())
  );
  const statusInvitation = useSelector(state =>
    state.invitation?.get("status")
  );
  const errorsInvitation = useSelector(state =>
    state.invitation?.get("errors", false)
  );

  const project = useSelector(state =>
    state.project?.getIn(["data", organizationId, projectId])
  );

  const environments = useSelectorWithUrlParams(environmentsSelector);

  useEffect(() => {
    return () => {
      cancel();
    };
  }, []);

  useEffect(() => {
    if (
      ["added", "updated", "deleted"].includes(statusAccess) ||
      ["added", "deleted"].includes(statusInvitation)
    ) {
      cancel();
    }
  }, [statusAccess, statusInvitation]);

  useEffect(() => {
    if (project?.id) dispatch(getAccesses({ organizationId, project }));
  }, [project]);

  useEffect(() => {
    dispatch(getInvitations({ organizationId, projectId }));
  }, [projectId]);

  const cancel = () => {
    handleEditInvitation();
    handleEditAccess();
  };

  const addNewAccess = () => {
    if (process.env.CUSTOM_USER_MANAGEMENT_URL) {
      window.location.href = process.env.CUSTOM_USER_MANAGEMENT_URL;
      return;
    }

    dispatch(editInvitation({ id: null }));
    dispatch(editAccess({ id: "new" }));
  };

  const handleEditAccess = (id = null) => {
    dispatch(editInvitation({ id: null }));
    dispatch(editAccess({ id }));
  };

  const updateProjectAccess = (access, role) => {
    dispatch(updateAccess({ organizationId, project, access, role }));
  };

  const handleCreateInvitation = (
    email,
    role,
    accesses = [],
    force = false
  ) => {
    if (checkUserExists(email)) return;
    dispatch(
      addInvitation({
        organizationId,
        projectId,
        email,
        environments: accesses,
        role,
        force
      })
    );
  };

  const checkUserExists = email => {
    const access = projectAccesses.find(elt => elt.getUser().email === email);
    const invit = invitations.find(elt => elt.email === email);
    if (access || invit) {
      setInvitAlert({ isOpen: true, user: access?.getUser() || invit });
      return true;
    }
    return false;
  };

  const handleEditInvitation = (id = null) => {
    dispatch(editAccess({ id: null }));
    dispatch(
      editInvitation({
        id
      })
    );
  };

  const handleRevokeInvit = invitation => {
    if (!invitation) return;
    dispatch(
      deleteInvitation({
        organizationId,
        projectId,
        invitation
      })
    );
    handleCloseModal();
  };

  const handleResendInvit = invitation => {
    if (!invitation) return;
    dispatch(
      addInvitation({
        organizationId,
        projectId,
        email: invitation.email,
        environments: invitation.environments,
        role: invitation.role,
        force: true,
        invitation
      })
    );
    handleCloseModal();
  };

  const openInvitModal = (invitation, type) => {
    setModal({ open: true, type, invitation });
  };

  const handleCloseModal = () => {
    setModal({ open: false, type: null, access: null, invitation: null });
  };

  const showAddUserButton =
    process.env.ENABLE_USER_MANAGEMENT &&
    project?.hasPermission &&
    project.hasPermission("#manage-access");

  if (!project) return null;

  return (
    <S.Wrapper>
      <LiveMessage
        message={`${project?.title} project-level access settings`}
        aria-live="polite"
      />
      <PageMeta title={`Access | ${project?.title}`} />

      {invitAlert.isOpen && (
        <InvitationAlert
          project={project.title}
          user={invitAlert.user}
          onClose={() => setInvitAlert({ isOpen: false, user: null })}
        />
      )}

      {showAddUserButton && (
        <ButtonAdd css={{ float: "right" }} onClick={addNewAccess} />
      )}

      <S.Heading id="settings-heading">
        {intl.formatMessage({ id: "access.title" })}
      </S.Heading>
      {environments?.toArray()[0]?.type ? (
        <S.EnvironmentTypeBanner level="warning">
          {intl.formatMessage({ id: "project.access.environment_types" })}{" "}
          <a
            href={intl.formatMessage({
              id: "links.documentation.users"
            })}
            rel="noopener noreferrer"
            target="_blank"
          >
            {intl.formatMessage({ id: "learnmore" })}
          </a>
        </S.EnvironmentTypeBanner>
      ) : (
        <PageDescription>
          {intl.formatMessage({ id: "project.accesslist.description" })}
        </PageDescription>
      )}
      <section aria-labelledby="settings-heading">
        {errorsAccess?.code === 409 && (
          <Error>
            {intl.formatMessage({ id: "project.accesslist.already_exists" })}
          </Error>
        )}
        {errorsInvitation && <Error>{errorsInvitation}</Error>}

        {editedAccess === "new" && (
          <div key={`access-create`}>
            <SettingLine
              id={`access-create`}
              icon={
                <UserIcon
                  size={30}
                  backgroundColor="black"
                  color="white"
                  padding={10}
                />
              }
              isOpen={true}
              isNew={true}
              addNewTitle={intl.formatMessage({
                id: "project.accesslist.add_user",
                defaultMessage: "Add user"
              })}
              onClick={() => handleEditAccess()}
            >
              <EnvironmentAccessListFields
                createInvitation={handleCreateInvitation}
                enabled={true}
                environments={environments}
                onCancel={cancel}
                projectErrors={errorsAccess}
              />
            </SettingLine>
          </div>
        )}

        {invitations?.entrySeq().map(([id, invitation]) => (
          <InvitationLine
            key={`invitation-${id}-line`}
            invitation={invitation}
            isEdited={editedInvitation === id}
            onEdit={() =>
              handleEditInvitation(editedInvitation === id ? null : id)
            }
            onResendInvitation={() => openInvitModal(invitation, "resend")}
            onRevoke={() => openInvitModal(invitation, "revoke")}
          />
        ))}

        {projectAccesses?.entrySeq().map(([id, access]) => (
          <SettingLine
            key={`access-${id}`}
            icon={
              <UserIcon
                size={30}
                backgroundColor="black"
                color="white"
                padding={10}
              />
            }
            info={
              <S.InfoLayout>
                <User user={access?.getUser()} />
                {access.hasPermission && access.hasPermission("#edit") ? (
                  <span className="role">
                    {access.role === "admin" ? "Project admin" : access.role}
                  </span>
                ) : (
                  <span className="role account-owner">
                    Account owner{" "}
                    <AccessibleTooltip
                      className="lock-icon"
                      tooltipProps={{
                        id: `access-${id}-tooltip`,
                        place: "top",
                        children: "You do not have permission to edit this user"
                      }}
                    >
                      <LockIcon />
                    </AccessibleTooltip>
                  </span>
                )}
              </S.InfoLayout>
            }
            addNewTitle={intl.formatMessage({
              id: "project.accesslist.add_user",
              defaultMessage: "Add user"
            })}
            isOpen={editedAccess === id}
            openText={
              access?.hasPermission && access.hasPermission("#edit")
                ? intl.formatMessage({ id: "edit" })
                : intl.formatMessage({ id: "view" })
            }
            onClick={() => handleEditAccess(editedAccess === id ? null : id)}
          >
            {editedAccess === id && (
              <EnvironmentAccessListFields
                access={access}
                createInvitation={handleCreateInvitation}
                enabled={access.hasPermission && access.hasPermission("#edit")}
                environments={environments}
                onCancel={cancel}
                onDelete={() =>
                  setModal({ open: true, type: "delete", access })
                }
                projectErrors={errorsAccess}
                updateProjectAccess={updateProjectAccess}
              />
            )}
          </SettingLine>
        ))}

        <ModalConfirmDelete
          isOpen={modal.open && modal.type === "delete"}
          closeModal={handleCloseModal}
          deleteFunction={() =>
            dispatch(
              deleteAccess({
                organizationId,
                projectId,
                access: modal.access
              })
            )
          }
          itemType="user"
          itemName={modal.access?.getUser().display_name}
          itemId={modal.access?.id}
        />

        <InvitationRevokeModal
          isOpen={modal.open && modal.type === "revoke"}
          closeModal={handleCloseModal}
          email={modal.invitation?.email}
          revokeInvitation={() => handleRevokeInvit(modal.invitation)}
        />

        <InvitationResendModal
          isOpen={modal.open && modal.type === "resend"}
          closeModal={handleCloseModal}
          email={modal.invitation?.email}
          resendInvitation={() => handleResendInvit(modal.invitation)}
        />

        {isLoading && <Loading />}
      </section>
    </S.Wrapper>
  );
};

export default withReducers({
  invitation: () => import("Reducers/invitation"),
  project: () => import("Reducers/project"),
  environment: () => import("Reducers/environment"),
  projectAccess: () => import("Reducers/project/access")
})(ProjectAccessList);
