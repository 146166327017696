import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { StripeProvider, Elements } from "react-stripe-elements";
import { Link } from "react-router-dom";

import withReducers from "Hocs/withReducers";

import { getPaymentSource } from "Reducers/paymentSource";
import client from "Libs/platform";
import { capitalize } from "Libs/utils";
import Heading3 from "Components/styleguide/Heading3";
import Button from "UI/Button";

import PaymentForm from "../../components/PaymentForm";
import AddressForm from "../../components/AddressForm";
import OrderDetailSummary from "../../components/OrderDetailSummary";
import TrialWidget from "../../components/TrialWidget";
import ModalChangeMethod from "../../components/PaymentForm/ModalChangeMethod";
import NewPaymentSource from "../../components/NewPaymentSource";

import * as S from "./Billing.styles";

const Billing = ({ cancel }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [modalConfirm, setModalConfirm] = useState(false);
  const [newPaymentSource, setNewPaymentSource] = useState(true);
  const [paymentTypes, setPaymentTypes] = useState([]);

  const username = useSelector(state => state.app?.getIn(["me", "username"]));
  const stripePublicKey = useSelector(state =>
    state.app?.getIn(["me", "data", "stripe", "public_key"])
  );
  const paymentSource = useSelector(state => state.paymentSource?.get("data"));

  useEffect(() => {
    getAllowed();
    dispatch(getPaymentSource());
  }, []);

  useEffect(() => {
    setNewPaymentSource(!(paymentSource?.id || paymentSource?.type));
  }, [paymentSource]);

  const getAllowed = async () => {
    const allowedTypes = await client.getPaymentSourcesAllowed();
    setPaymentTypes([...allowedTypes?.source_types]);
  };

  const confirmChange = () => {
    setModalConfirm(false);
    setNewPaymentSource(true);
  };

  const refreshPayment = async () => {
    dispatch(getPaymentSource());
    setNewPaymentSource(false);
  };

  const stripeKey = stripePublicKey
    ? stripePublicKey
    : "pk_test_6pRNASCoBOKtIshFeQd4XMUh";

  const existingPaymentSourceType =
    paymentSource?.source_type || paymentSource?.type || "credit-card";
  const hasPaymentSource = !!(paymentSource?.id || paymentSource?.type);

  return (
    <S.BillingDetailsContainer>
      <S.Aside>
        <S.InvoicesSection>
          <S.HeaderLayout>
            <Heading3>
              {intl.formatMessage({ id: "upcoming_invoice" })}
            </Heading3>
            <Link to={`/-/users/${username}/billing/history`}>
              {intl.formatMessage({ id: "view_all" })}
            </Link>
          </S.HeaderLayout>
          <S.SideSectionBox>
            <OrderDetailSummary />
          </S.SideSectionBox>

          <TrialWidget hasPaymentSource={hasPaymentSource} />
        </S.InvoicesSection>
      </S.Aside>

      <S.Content>
        <S.PaymentMethodsSection>
          <ModalChangeMethod
            isOpen={modalConfirm}
            cancel={() => setModalConfirm(false)}
            confirm={confirmChange}
          />
          {newPaymentSource && (
            <NewPaymentSource
              finalize={refreshPayment}
              close={() => setNewPaymentSource(false)}
              hasPaymentSource={hasPaymentSource}
              paymentTypes={paymentTypes}
            />
          )}

          {hasPaymentSource && existingPaymentSourceType !== "invoice_account" && (
            <>
              <S.HeaderLayoutChangePayment>
                {newPaymentSource ? (
                  <Heading3>
                    {intl.formatMessage({ id: "existing_payment_method" })}
                  </Heading3>
                ) : (
                  <>
                    <Heading3>
                      {intl.formatMessage({ id: "payment_method" })}
                    </Heading3>
                    <span>{intl.formatMessage({ id: "only_one_method" })}</span>
                  </>
                )}
                <Button
                  id="change-method"
                  onClick={() => setModalConfirm(true)}
                  aria-label={intl.formatMessage({ id: "change_method" })}
                  disabled={newPaymentSource}
                >
                  {capitalize(intl.formatMessage({ id: "change_method" }))}
                </Button>
              </S.HeaderLayoutChangePayment>
              {(existingPaymentSourceType === "credit-card" ||
                existingPaymentSourceType === "stripe_sepa_debit") && (
                <S.SectionBox>
                  <Heading3>
                    {intl.formatMessage({
                      id: existingPaymentSourceType
                    })}
                  </Heading3>
                  {existingPaymentSourceType === "credit-card" && (
                    <p>
                      {intl.formatMessage({
                        id: "payment_information_description"
                      })}
                    </p>
                  )}
                  {existingPaymentSourceType === "credit-card" ||
                    (existingPaymentSourceType === "stripe_sepa_debit" && (
                      <StripeProvider apiKey={stripeKey}>
                        <Elements>
                          <PaymentForm
                            currentPaymentSourceType={existingPaymentSourceType}
                            edit={false}
                            number={paymentSource?.number}
                          />
                        </Elements>
                      </StripeProvider>
                    ))}
                </S.SectionBox>
              )}
            </>
          )}
        </S.PaymentMethodsSection>

        <S.BillingDetailsSection>
          <S.HeaderLayout>
            <Heading3>{intl.formatMessage({ id: "billing_details" })}</Heading3>
          </S.HeaderLayout>
          <S.SectionBox>
            <AddressForm
              goForwardText={intl.formatMessage({
                id: "update_billing"
              })}
              finalize={cancel}
            />
          </S.SectionBox>
        </S.BillingDetailsSection>
      </S.Content>
    </S.BillingDetailsContainer>
  );
};

Billing.propTypes = {
  cancel: PropTypes.func
};

export default withReducers({
  paymentSource: () => import("Reducers/paymentSource")
})(Billing);
