import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Map } from "immutable";

import {
  environmentSelector,
  environmentLoadingSelector
} from "Reducers/environment";

import ModalWrapper from "Components/Modal";
import Button from "Components/Button";
import ContentPaneLayout from "Components/ContentPaneLayout";
import Loading from "Components/Loading";
import Error from "Components/Error";
import CopyableArea from "Components/CopyableArea";
import ButtonWrapper from "Components/ButtonWrapper";
import Heading4 from "Components/styleguide/Heading4";
import InfoDialog from "Components/InfoDialog";

import { goToEnvironment } from "Libs/utils";
import { backup } from "Reducers/environment/actions/backup";

class EnvironmentBackupPane extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      environment = {},
      push,
      organizationId,
      projectId,
      environmentId,
      intl,
      backup,
      errors,
      isLoading,
      isEnvironmentLoading
    } = this.props;

    return (
      <ModalWrapper
        id="environment-backup-modal"
        shouldCloseOnOverlayClick={true}
        isOpen={true}
        title={[
          intl.formatMessage(
            { id: "backup.title" },
            { environmentName: environment.name }
          )
        ]}
        onRequestClose={() =>
          goToEnvironment(push, organizationId, projectId, environmentId)
        }
        closeModal={() =>
          goToEnvironment(push, organizationId, projectId, environmentId)
        }
        modalClass="modal-medium modal-environment-action modal-environment-backup"
        announceTitle={intl.formatMessage(
          { id: "backup.title" },
          { environmentName: environment.name }
        )}
      >
        <ContentPaneLayout className="modal-body">
          {errors && errors.get("message") && (
            <Error>{errors.get("message")}</Error>
          )}
          <p>
            Creates a complete backup of the environment. It includes all
            persistent data from all running services (MySQL, SOLR,...) and any
            files stored on the mounted volumes.
          </p>
          <Heading4 style={{ marginBottom: 16 }}>
            CLI{" "}
            <InfoDialog
              title="Learn more"
              text={`You can also use this terminal command to create a backup of ${environment.title}`}
              to={intl.formatMessage({
                id: "links.documentation.getting_started_CLI"
              })}
              linkText="Help"
            />
          </Heading4>
          <CopyableArea
            id="environment-backup-cmd"
            content="platform backup:create"
            singleLine={true}
          >
            platform backup:create
          </CopyableArea>
          {isEnvironmentLoading || isLoading ? (
            <Loading />
          ) : (
            <ButtonWrapper className="modal-buttons">
              <Button
                id="environment-backup-save-btn"
                type="submit"
                aria-label={intl.formatMessage({ id: "backup.button" })}
                onClick={() => backup()}
              >
                {intl.formatMessage({ id: "backup.button" })}
              </Button>
              <Button
                id="environment-backup-cancel-btn"
                className="secondary"
                type="button"
                aria-label={intl.formatMessage({ id: "cancel" })}
                onClick={() =>
                  goToEnvironment(
                    push,
                    organizationId,
                    projectId,
                    environmentId
                  )
                }
              >
                {intl.formatMessage({ id: "cancel" })}
              </Button>
            </ButtonWrapper>
          )}
        </ContentPaneLayout>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = (
  state,
  { environmentId, organizationId, projectId }
) => {
  const environment = environmentSelector(state, {
    organizationId,
    projectId,
    environmentId
  });

  return {
    environment,
    parent: environmentSelector(state, {
      organizationId,
      projectId,
      environmentId: environment?.parent
    }),
    isEnvironmentLoading: environmentLoadingSelector(state, {
      projectId,
      environmentId
    }),
    isLoading: state.backup.get("loading"),
    errors: state.backup.get("errors", new Map())
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  backup: () =>
    dispatch(backup(props.organizationId, props.projectId, props.environmentId))
});

EnvironmentBackupPane.propTypes = {
  environment: PropTypes.object,
  parent: PropTypes.object,
  intl: PropTypes.object,
  errors: PropTypes.object,
  isLoading: PropTypes.bool,
  isEnvironmentLoading: PropTypes.bool,
  push: PropTypes.func,
  backup: PropTypes.func,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  environmentId: PropTypes.string.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EnvironmentBackupPane));
