import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Map } from "immutable";

import withReducers from "Hocs/withReducers";
import useDecodedParams from "Hooks/useDecodedParams";

import { getCatalog, saveConfig } from "Reducers/project/setup";
import { getOrganizationCatalog } from "Reducers/organization/setup";
import { canCreateProjectOrganizationsSelector } from "Reducers/organization";

import CloseBtn from "Components/CloseBtn";

import Breadcrumbs from "../../components/Breadcrumbs";

const STEPS = ["type", "template", "info", "building"];

import * as S from "./ProjectSetup.styles";

const ProjectSetup = ({ children, goToStep }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { pathname, search } = useLocation();
  const { replace } = useHistory();
  const { step } = useParams();
  const { organizationId: organizationNameParams } = useDecodedParams();

  const [steps, setSteps] = useState(STEPS);
  const [currentStep, setCurrentStep] = useState(STEPS[0]);

  const params = new URLSearchParams(search);
  const config = useSelector(({ setup }) => setup?.get("config", Map()));

  const organizations = useSelector(state =>
    canCreateProjectOrganizationsSelector(state)?.toArray()
  );

  const organizationIdParams = organizations?.find(
    o => o.name === organizationNameParams
  )?.id;

  const organizationId = config?.organization?.value || organizationIdParams;

  useEffect(() => {
    if (
      process.env.ENABLE_ORGANIZATION &&
      organizationNameParams &&
      organizationNameParams !== "projects" &&
      !organizationId
    ) {
      // This org does not exist or user does not have access, let's redirect
      replace("/projects/create-project");
    } else {
      if (
        process.env.ENABLE_ORGANIZATION &&
        organizationNameParams &&
        organizationNameParams !== "projects"
      ) {
        dispatch(
          getOrganizationCatalog({
            organizationId: organizationNameParams,
            catalogUrl: params.get("catalog")
          })
        );
      } else {
        dispatch(
          getCatalog({
            catalogUrl: params.get("catalog")
          })
        );
      }

      if (params.has("template")) {
        const template = params.get("template");
        dispatch(saveConfig({ template }));
        goToStep(`info?template=${template}`);
      }
    }
  }, [organizationNameParams]);

  useEffect(() => {
    updateSteps();
  }, [pathname]);

  useEffect(() => {
    setCurrentStep(step ? step : steps[0]);
  }, [step]);

  const updateSteps = () => {
    let skip = [];

    if (params.has("template")) {
      skip.push("type");
      skip.push("template");
    }

    if (step !== "template" && !config.has("template")) {
      skip.push("template");
    }

    setSteps(STEPS.filter(step => !skip.includes(step)));
  };

  // If the user has multiple organizations, calling accounts directly
  // will no longer work to get the catalog etc...
  if (organizationNameParams === "projects" && organizations?.length > 1) {
    // Redirect to the first organization in the list
    // the user can change his organization later in the setup
    replace(`/${organizations[0].name}/create-project`);
    return null;
  }

  return (
    <S.Layout>
      <S.Wrapper className="step-wrapper col-10">
        <CloseBtn
          toLink={"/"}
          closeText={intl.formatMessage({ id: "cancel" })}
        />

        <Breadcrumbs
          steps={steps}
          currentStep={currentStep}
          goToStep={goToStep}
        />

        <S.Step>{children}</S.Step>
      </S.Wrapper>
    </S.Layout>
  );
};

ProjectSetup.propTypes = {
  goToStep: PropTypes.func,
  children: PropTypes.node
};

export default withReducers({
  region: () => import("Reducers/project/region"),
  setup: () => import("Reducers/project/setup"),
  organizationSetup: () => import("Reducers/organization/setup"),
  organization: () => import("Reducers/organization")
})(ProjectSetup);
