import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import momentTZ from "moment-timezone";

import { capitalize, getRegionLabel } from "Libs/utils";

import Button from "UI/Button";
import InputField from "Components/fields/InputField";
import Dropdown from "Components/Dropdown";

import * as S from "./ProjectGeneralSettingsForm.styles";

const timezoneList = momentTZ.tz.names().map(tz => {
  return { label: tz, value: tz };
});

const ProjectBasicForm = ({ onSave, project }) => {
  const [name, setName] = useState(project.title);
  const [timezone, setTimezone] = useState(project.data?.timezone);
  const [isChanged, setIsChanged] = useState(false);

  const intl = useIntl();

  const localSave = e => {
    e.preventDefault();
    onSave({ timezone, title: name });
    setIsChanged(false);
  };

  const onNameChange = e => {
    setName(e.target.value);
    setIsChanged(true);
  };

  const onTimezoneChange = tz => {
    setTimezone(tz.value);
    setIsChanged(true);
  };

  const cancel = () => {
    setName(project.title);
    setTimezone(project.data?.timezone);
    setIsChanged(false);
  };

  const formattedRegion = getRegionLabel(project.region_label);

  return (
    <S.Form aria-label="Project settings">
      <S.FormFields>
        <InputField
          onChange={onNameChange}
          id="project-name"
          label={intl.formatMessage({
            id: "project.settings.basic.form.name",
            defaultMessage: "Project name"
          })}
          value={name}
        />
        <S.FakeInput>
          <div>
            <label>
              {intl.formatMessage({
                id: "project.settings.basic.form.region",
                defaultMessage: "Region"
              })}
            </label>
            <div>
              {formattedRegion.title} <span>{formattedRegion.suffix}</span>
            </div>
          </div>
        </S.FakeInput>
        <S.Timezone>
          <Dropdown
            responsive
            label={intl.formatMessage({
              id: "project.settings.basic.form.timezone.label",
              defaultMessage: "Timezone"
            })}
            options={timezoneList}
            defaultValue={{
              label: timezone,
              value: timezone
            }}
            searchable
            onChange={onTimezoneChange}
            clearable={false}
            fieldType={true}
            required={true}
            defaultText={intl.formatMessage({
              id: "project.settings.basic.form.timezone.placeholder",
              defaultMessage: "Select timezone"
            })}
          />
          <S.Info
            text={intl.formatMessage({
              id: "project.settings.basic.form.timezone.info",
              defaultMessage:
                "The project timezone is used to schedule backups."
            })}
          />
        </S.Timezone>
      </S.FormFields>

      {isChanged && (
        <S.ButtonsWrapper>
          <Button
            id="save_project_general_settings_button"
            type="submit"
            aria-label={intl.formatMessage({ id: "save" })}
            onClick={localSave}
          >
            {capitalize(intl.formatMessage({ id: "save" }))}
          </Button>
          <Button
            id="cancel-project-general-btn"
            type="button"
            variant="secondary"
            aria-label={intl.formatMessage({ id: "cancel" })}
            onClick={cancel}
          >
            {capitalize(intl.formatMessage({ id: "cancel" }))}
          </Button>
        </S.ButtonsWrapper>
      )}
    </S.Form>
  );
};

ProjectBasicForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

export default ProjectBasicForm;
