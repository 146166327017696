import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import Button from "UI/Button";
import { capitalize } from "Libs/utils";

import AppVersion from "Components/AppVersion";
import CheckboxField from "Components/fields/CheckboxField";
import RadioField from "Components/fields/RadioField";

import BasicFormSettingsAside from "./BasicFormSettingsAside";

import {
  ButtonWrapper,
  Container,
  RightPane,
  Section
} from "./BasicFormSettings.styles";
import { colorSchemeFields } from "./options";

const BasicFormSettings = props => {
  const { formatMessage: t, formatMessage } = useIntl();
  const { marketing, ui_colorscheme, ...propsForAside } = props;

  const initialFormValues = {
    marketing,
    ui_colorscheme
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [changedValues, setChangedValues] = useState({});

  useEffect(() => {
    const updatedFormValues = {
      marketing: marketing,
      ui_colorscheme: ui_colorscheme
    };
    setFormValues(updatedFormValues);
  }, [props.username, props.picture, props.cacheKey]);

  const onFieldChange = (value, fieldName) => {
    const fieldUpdates = {
      ...changedValues,
      [fieldName]: value
    };
    const updatedFormValues = {
      ...formValues,
      ...fieldUpdates
    };
    setFormValues(updatedFormValues);
    setChangedValues(fieldUpdates);
  };

  const onSave = () => {
    props.onSave(changedValues);

    setChangedValues({});
  };

  const onCancel = () => {
    setFormValues(initialFormValues);
    setChangedValues({});
  };

  const isChanged = Object.keys(changedValues).length > 0;

  return (
    <Container>
      <BasicFormSettingsAside {...propsForAside} />
      <RightPane>
        <Section>
          <article>
            <h2>{t({ id: "display_settings" })}</h2>
            <p>
              {t(
                { id: "ui_color_scheme_help_text" },
                {
                  // eslint-disable-next-line
                  cta: str => (
                    <a
                      href={formatMessage({
                        id: "links.documentation.WCAG"
                      })}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {str}
                    </a>
                  ), // eslint-disable-line
                  verb: t({ id: "learnmore" })
                }
              )}
            </p>
            {Object.entries(colorSchemeFields).map(([key, value]) => (
              <RadioField
                id={`${key}-label`}
                key={key}
                forId={`${key}`}
                title={t({ id: value.messageId })}
                label={t({
                  id: value.messageId
                })}
                onChange={() => onFieldChange(key, "ui_colorscheme")}
                value={formValues.ui_colorscheme === key}
                marginBottom="24px"
              />
            ))}
          </article>
          <article>
            <h2>{capitalize(t({ id: "notification_settings" }))}</h2>
            <CheckboxField
              id="newsletter-label"
              forId="newsletter"
              title={t({ id: "newsletter" })}
              label={t({
                id: "newsletter.description"
              })}
              onChange={e => onFieldChange(e.target.checked, "marketing")}
              value={formValues.marketing}
            />

            {isChanged && (
              <ButtonWrapper>
                <Button
                  id="save_organization_general_settings_button"
                  className="primary"
                  onClick={onSave}
                  aria-label={t({ id: "save" })}
                >
                  {capitalize(t({ id: "save" }))}
                </Button>
                <Button
                  id="cancel-organization-name-btn"
                  onClick={onCancel}
                  variant="secondary"
                  aria-label={t({ id: "cancel" })}
                >
                  {capitalize(t({ id: "cancel" }))}
                </Button>
              </ButtonWrapper>
            )}
          </article>
        </Section>
        <AppVersion />
      </RightPane>
    </Container>
  );
};

// Prop keys for the form need to match
// what they are in the user object from the API
// ex: first_name and not firstName. This
// makes it easier to handling saving the form data.
BasicFormSettings.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  username: PropTypes.string,
  email: PropTypes.string,
  company: PropTypes.string,
  website: PropTypes.string,
  marketing: PropTypes.bool,
  onSave: PropTypes.func,
  ui_colorscheme: PropTypes.string,
  emailReset: PropTypes.string,
  cacheKey: PropTypes.string,
  resetEmail: PropTypes.string,
  picture: PropTypes.string,
  onPictureError: PropTypes.func
};

export default BasicFormSettings;
