import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Map } from "immutable";
import styled from "styled-components";

const ENVIRONMENT_LIMIT_ERROR_CODES = [402];
import { ENVIRONMENT_TYPES } from "Constants/constants";

import {
  environmentSelector,
  environmentsSelector,
  environmentLoadingSelector
} from "Reducers/environment";

import { capitalize, goToEnvironment } from "Libs/utils";
import ModalWrapper from "Components/Modal";
import InputField from "Components/fields/InputField";
import Button from "UI/Button";
import ContentPaneLayout from "Components/ContentPaneLayout";
import Loading from "Components/Loading";
import Error from "Components/Error";
import CopyableArea from "Components/CopyableArea";
import ButtonWrapper from "Components/ButtonWrapper";
import Heading4 from "Components/styleguide/Heading4";
import InfoDialog from "Components/InfoDialog";
import Dropdown from "Components/Dropdown";

import { branch } from "Reducers/environment/actions/branch";

import ErrorModal from "./ErrorModal";

const Inputs = styled.div`
  display: flex;

  & > div + div {
    margin-left: 16px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    & > div + div {
      margin-left: 0;
    }
    .select-box {
      width: 535px;
    }
  }
  @media (max-width: 375px) {
    .select-box {
      width: 309px;
    }
  }
`;

const DropdownWrapper = styled.div`
  .select-control {
    border: none;
    background: #f7f7f7 !important;
    padding: 0 10px;
    line-height: 40px;
    height: 40px;
    width: 300px !important;
    max-width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    margin-bottom: 20px;
  }
  .select-box__control {
    min-height: 38px;
    height: 40px;
    .select-box__value-container {
      height: 40px;
      .select-box__single-value {
        height: 40px;
        line-height: 38px;
      }
    }
  }
  .dropdown > div {
    display: block;
  }
  .highlight {
    bottom: 21px;
  }
`;

class EnvironmentBranchPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      branchName: ""
    };
    this.onBranchNameChange = this.onBranchNameChange.bind(this);
    this.onEnvironmentTypeChange = this.onEnvironmentTypeChange.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onBranchNameChange(e) {
    const branchName = e.target.value;

    this.setState(() => ({
      branchName
    }));
  }

  onEnvironmentTypeChange({ value }) {
    this.setState(() => ({
      type: value
    }));
  }

  render() {
    const {
      environment = {},
      subscriptionId,
      canEditPlan,
      hasEnvLimitError,
      push,
      organizationId,
      projectId,
      environmentId,
      intl,
      branch,
      isLoading,
      isEnvironmentLoading,
      project
    } = this.props;

    const { branchName, type, errors } = this.state;
    let branchNameToken = "new-branch";
    branchName
      ? (branchNameToken = branchName.replace(
          /(\s|~|\^|:|\?|\*|\[|\\|\/{2,}){1}/g,
          "-"
        ))
      : "";
    const envTypeOption = environment?.type && type ? ` --type ${type}` : "";
    const gitCommand = `${intl.formatMessage({
      id: "CLI_command_name"
    })} branch ${branchNameToken} ${environmentId}${envTypeOption}`;

    const isEnvLimitReached =
      hasEnvLimitError ||
      ENVIRONMENT_LIMIT_ERROR_CODES.includes(errors?.get("code"));

    return isEnvLimitReached ? (
      <ErrorModal
        project={project?.data}
        organizationId={organizationId}
        projectId={projectId}
        environmentId={environmentId}
        subscriptionId={subscriptionId}
        canEditPlan={canEditPlan}
      />
    ) : (
      <ModalWrapper
        id="environment-branch-modal"
        shouldCloseOnOverlayClick={true}
        isOpen={!hasEnvLimitError}
        onRequestClose={() =>
          goToEnvironment(push, organizationId, projectId, environmentId)
        }
        title={intl.formatMessage(
          { id: "branching_from" },
          { environment: environment.title }
        )}
        modalClass="modal-medium modal-environment-action modal-environment-branch"
        closeModal={() =>
          goToEnvironment(push, organizationId, projectId, environmentId)
        }
        announceTitle={intl.formatMessage(
          { id: "branching_from" },
          {
            environment: environment.name
          }
        )}
      >
        <ContentPaneLayout
          id="environment-branch-modal-body"
          className="modal-body"
        >
          {this.state.errors &&
          (this.state.errors.get("message") ||
            this.state.errors.get("detail")) ? (
            <Error>
              {this.state.errors.get("message") && (
                <p>{this.state.errors.get("message")}</p>
              )}
              {this.state.errors.get("detail") && (
                <p>{this.state.errors.get("detail")}</p>
              )}
            </Error>
          ) : (
            false
          )}
          <p>{intl.formatMessage({ id: "branch.create.prompt" })}</p>
          <Inputs>
            <InputField
              id="environment-branch-name-input"
              label="Name"
              placeholder={intl.formatMessage({
                id: "branch.name.placeholder"
              })}
              onChange={this.onBranchNameChange}
              value={branchName}
            />
            {environment?.type && (
              <DropdownWrapper>
                <Dropdown
                  id="environment-type-options"
                  className="environment-type"
                  label={intl.formatMessage({
                    id: "environment_type"
                  })}
                  options={ENVIRONMENT_TYPES.filter(
                    e => e !== "production"
                  ).map(et => ({
                    value: et,
                    label: capitalize(et)
                  }))}
                  onChange={this.onEnvironmentTypeChange}
                  defaultValue={{
                    value: "development",
                    label: "Development"
                  }}
                  searchable={false}
                  clearable={false}
                  error={this.props.errors.type}
                  disabled={
                    environment.hasPermission &&
                    !environment.hasPermission("#edit")
                  }
                  aria-disabled={
                    environment.hasPermission &&
                    !environment.hasPermission("#edit")
                  }
                />
              </DropdownWrapper>
            )}
          </Inputs>
          <Heading4 style={{ marginBottom: 16 }}>
            CLI{" "}
            <InfoDialog
              title="Learn more"
              text={`You can also use this terminal command to branch from ${environment.title}`}
              to={intl.formatMessage({
                id: "links.documentation.getting_started_CLI"
              })}
              linkText="Help"
            />
          </Heading4>
          <CopyableArea
            id="environment-branch-cmd"
            content={gitCommand}
            singleLine={true}
          >
            {gitCommand}
          </CopyableArea>
          {isEnvironmentLoading || isLoading ? (
            <Loading />
          ) : (
            <ButtonWrapper className="modal-buttons">
              <Button
                id="environment-branch-save-btn"
                type="submit"
                aria-label={intl.formatMessage({ id: "branch.button" })}
                onClick={() => {
                  if (branchName) {
                    branch(branchName, type);
                  }
                }}
              >
                {capitalize(intl.formatMessage({ id: "branch.button" }))}
              </Button>
              <Button
                id="environment-branch-cancel-btn"
                variant="secondary"
                type="button"
                aria-label={intl.formatMessage({ id: "cancel" })}
                onClick={() =>
                  goToEnvironment(
                    push,
                    organizationId,
                    projectId,
                    environmentId
                  )
                }
              >
                {capitalize(intl.formatMessage({ id: "cancel" }))}
              </Button>
            </ButtonWrapper>
          )}
        </ContentPaneLayout>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = (
  state,
  { environmentId, organizationId, projectId }
) => {
  const nonInactiveEnvironments = Object.values(
    environmentsSelector(state, {
      organizationId,
      projectId
    }).toJS()
  ).filter(({ status, is_main }) => status !== "inactive" && !is_main).length;

  const project = state.project.getIn(["data", organizationId, projectId]);

  return {
    environment: environmentSelector(state, {
      organizationId,
      projectId,
      environmentId
    }),
    subscriptionId: project?.subscription_id,
    hasEnvLimitError:
      nonInactiveEnvironments >= project?.subscription?.environments,
    canEditPlan: project?.hasPermission("#subscription_change"),
    isEnvironmentLoading: environmentLoadingSelector(state, {
      projectId,
      environmentId
    }),
    isLoading: state.branch.get("loading"),
    errors: state.branch.get("errors", new Map()),
    project
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  branch: (branchName, type) =>
    dispatch(
      branch(props.organizationId, props.projectId, props.environmentId, {
        branchName,
        type
      })
    )
});

EnvironmentBranchPane.propTypes = {
  environment: PropTypes.object,
  subscriptionId: PropTypes.string,
  hasEnvLimitError: PropTypes.bool,
  canEditPlan: PropTypes.bool,
  intl: PropTypes.object,
  errors: PropTypes.object,
  isLoading: PropTypes.bool,
  isEnvironmentLoading: PropTypes.bool,
  push: PropTypes.func,
  branch: PropTypes.func,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  environmentId: PropTypes.string.isRequired,
  project: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EnvironmentBranchPane));
