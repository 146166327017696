import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import getIcon from "Icons/technologies";
import WorkerIcon from "Icons/WorkerIcon";

import { capitalize } from "Libs/utils";

import AccessibleTooltip from "Components/AccessibleTooltip";

import Metric from "./Metric";
import LargeMetric from "./LargeMetric";
import ShareLink from "../../components/ShareLink/ShareLink";

import * as S from "./Service.styles";

const hasService = name => window.location.search.includes(`service=${name}`);

const getShareLink = service => {
  const search = new URLSearchParams(location.search);
  search.set("service", service);

  return `${location.origin}${location.pathname}?${search.toString()}`;
};

const Service = ({
  service,
  selectedTimeframe,
  onBrush,
  hosts,
  isLoading,
  kind
}) => {
  const container = useRef();
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (container.current && hasService(service.name)) {
      container.current.scrollIntoView();
    }
  }, [service.name, container.current]);

  const onToggle = useMemo(
    () => () => {
      setIsOpen(!isOpen);
    },
    [isOpen]
  );
  const data = service.metrics || {};

  const hasMetrics = Object.entries(data).some(([, value]) => value.length);

  if (!hasMetrics) return null;

  return (
    <>
      <S.Layout ref={container} isOpen={isOpen}>
        <S.Header isOpen={isOpen}>
          <S.Title>
            <S.Icon
              alt={service.name}
              src={`data:image/svg+xml;base64,${window.btoa(
                getIcon(service.icon, 0, 0, 24, 24)
              )}`}
            />
            {service.name}
            {service.kind === "worker" && (
              <S.WorkerBadge>
                <AccessibleTooltip
                  tooltipProps={{
                    id: `user-${service.name}`,
                    children: "Worker"
                  }}
                >
                  <WorkerIcon />
                </AccessibleTooltip>
              </S.WorkerBadge>
            )}
          </S.Title>
          <S.Actions>
            <S.Toggle type="button" onClick={onToggle}>
              {isOpen ? (
                <FormattedMessage id="metrics.grid.hide" />
              ) : (
                <FormattedMessage id="metrics.grid.view" />
              )}
            </S.Toggle>
            <ShareLink link={getShareLink(service.name)} />
          </S.Actions>
        </S.Header>
        {!isOpen && (
          <S.Grid>
            {Object.entries(data).map(
              ([key, value]) =>
                value?.length && (
                  <Metric
                    key={key}
                    name={key}
                    value={value[value.length - 1]["average@value"]}
                    max={value[value.length - 1].max}
                    percentage={value[value.length - 1]["average@percentage"]}
                    data={value.map(v => ({
                      timestamp: v.timestamp,
                      value: isNaN(v["average@value"]) ? 0 : v["average@value"]
                    }))}
                    isLoading={isLoading}
                    kind={kind}
                    serviceKind={capitalize(service.kind)}
                  />
                )
            )}
          </S.Grid>
        )}
      </S.Layout>
      {isOpen && (
        <S.ChartLayout>
          <S.GridLG>
            {Object.entries(data).map(
              ([name, value]) =>
                value?.length && (
                  <LargeMetric
                    key={name}
                    isLoading={isLoading}
                    onBrush={onBrush}
                    hosts={hosts}
                    selectedTimeframe={selectedTimeframe}
                    name={name}
                    value={value}
                    kind={kind}
                    serviceKind={capitalize(service.kind)}
                  />
                )
            )}
          </S.GridLG>
        </S.ChartLayout>
      )}
    </>
  );
};

Service.propTypes = {
  service: PropTypes.shape({
    name: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.string,
    metrics: PropTypes.object,
    kind: PropTypes.string
  }),
  selectedTimeframe: PropTypes.object,
  onBrush: PropTypes.func,
  hosts: PropTypes.array,
  isLoading: PropTypes.bool,
  kind: PropTypes.string
};

export default Service;
