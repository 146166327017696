import styled from "styled-components";
import Heading2 from "Components/styleguide/Heading2";
import InfoDialog from "Components/InfoDialog";

import { css, SECTION } from "Libs/themes";

export const Form = styled.form`
  display: inline-block;
  width: 100%;
  padding: 0;
  font-size: 14px;
  line-height: 24px;

  .label .required-notice {
    vertical-align: middle;
  }

  .rcs-custom-scroll,
  .input-field {
    width: 100%;
  }

  .text-area-field {
    margin-bottom: 16px;
  }

  .ide-field {
    width: 100%;
    height: 300px;
  }

  label {
    font-size: 14px;
  }

  textarea {
    font: normal 15px/20px "Open Sans";
    color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  }

  input::placeholder,
  textarea::placeholder {
    font-family: "Open Sans";
    color:var(--section-granite-color,var(--section-granite,var(--granite)));
    opacity: 1;
  }
`;

export const Field = styled.div`
  position: relative;
  &.large {
    width: 100% !important;
  }
`;

export const Info = styled(InfoDialog)`
  position: absolute;
  top: 0;
  right: 0;
`;

export const Header = styled.header`
  font-size: 14px;

  > div {
    display: inline-block;
  }
  svg {
    vertical-align: middle;
  }
  ${Heading2} {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 16px;
  }

  p {
    margin: 24px 0 32px 0;

    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const Options = styled.div`
  margin-top: 26px;
  .checkbox-field {
    margin-bottom: 10px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  margin-top: 36px;

  button {
    margin-right: 8px;
  }
  #remove {
    margin-right: 0;
    margin-left: auto;
  }
`;
