import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

import Button from "UI/Button";
import Pane from "../../../../components/Pane";
import Paragraph from "../../../../components/Paragraph";

const RecoveryCodes = ({ recoveryCodes, onResetCodes }) => (
  <Pane
    header={
      <strong>
        <FormattedMessage id="security.recovery.header" />
      </strong>
    }
  >
    <Paragraph>
      <FormattedMessage id="security.recovery.description" />
    </Paragraph>

    {!!recoveryCodes?.length && (
      <ul>
        {recoveryCodes.map(code => (
          <li key={code}>{code}</li>
        ))}
      </ul>
    )}

    <Button onClick={onResetCodes}>
      <FormattedMessage id="security.recovery.reset" />
    </Button>
  </Pane>
);

RecoveryCodes.propTypes = {
  recoveryCodes: PropTypes.arrayOf(PropTypes.string),
  onResetCodes: PropTypes.func
};

export default RecoveryCodes;
