import styled, { css as styledCSS } from "styled-components";
import { semiBoldAlias, makePalette } from "Libs/theme";

import { SECTION, MENU, BUTTON, css } from "Libs/themes";

const theme = makePalette((palette, props) => ({
  layout_color: {
    default: palette.slate,
    dark: palette.snow
  },
  toggle_color: {
    default: props.isActive ? palette.skye : palette.granite,
    dark: props.isActive ? palette.snow : palette.granite,
    contrast: props.isActive ? palette.snow : palette.slate
  },
  toggle_background: {
    default: props.isActive ? palette.skyeLight : "transparent",
    dark: props.isActive ? palette.ebonyLight : "transparent",
    contrast: props.isActive ? palette.skyeDark : "transparent"
  },
  toggle_background_hover: {
    default: palette.skyeLight,
    dark: palette.ebonyLight,
    contrast: palette.skyeDark
  }
}));

export const Layout = styled.div`
  padding-right: 16px;
  padding-left: 8px;
  background-color:var(--menu-snow-background-color,var(--menu-snow,var(--snow)));
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 32px;
  box-sizing: border-box;
  color: ${theme.layout_color};
  font-size: 13px;
  line-height: 18px;
  ${semiBoldAlias};
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: 16px;
  padding-right: 8px;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 16px;
    background-color:var(--section-periwinkle-grey-background-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
    right: 0;
    top: 4px;
  }
`;

export const ListItem = styled.li`
  position: relative;
  display: inline-block;

  &:not(:last-child) {
    margin-right: 2px;
  }
`;

export const Toggle = styled.button.attrs(props => ({
  "aria-pressed": props.isActive ? "true" : null
}))`
  ${semiBoldAlias};
  cursor: pointer;
  font-size: 13px;
  line-height: 18px;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color:var(--menu-snow-background-color,var(--menu-snow,var(--snow)));
  height: 24px;
  ${({ isActive }) =>
    isActive
      ?"color:var(--timerange-button-color,var(--button-skye-color,var(--button-skye,var(--skye))))":"color:var(--button-granite-color,var(--button-granite,var(--granite)))"};
  text-decoration: none;

  transition-duration: 0.25s;
  transition-property: color, background-color;

  :hover {
    background-color:var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)));
  }

  ${({ isActive }) =>
    isActive &&
    styledCSS`
      border-radius: 2px;
      outline: none;
      background-color:var(--timerange-section-background-color,var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light))));
    `};
`;
