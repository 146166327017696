import styled from "styled-components";

import ContentLayout from "Components/ContentLayout";

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  flex: 100%;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  a,
  button {
    height: auto;
    margin-left: 8px;
  }
`;

export const TicketLayout = styled(ContentLayout)`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &,
  * {
    box-sizing: border-box;
  }
`;

export const MainContent = styled.div`
  @media screen and (min-width: 1200px) {
    width: calc(65% - 16px);
    margin-right: 32px;
  }
`;

export const Sidebar = styled.div`
  @media screen and (min-width: 1200px) {
    width: calc(35% - 16px);
    display: block;
  }
`;

export const Spacer = styled.div`
  margin-top: 32px;
`;
