import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { List } from "immutable";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";

import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import { loadEnvironment, environmentSelector } from "Reducers/environment";
import {
  loadEnvironmentActivities,
  loadMoreEnvironmentActivities
} from "Reducers/activity";

import { Button } from "@platformsh/ui-kit";
import BackupIcon from "Icons/BackupIcon";

import ContentLayout from "Components/ContentLayout";
import Heading2 from "Components/styleguide/Heading2";
import EmptyBackups from "Components/illustrations/EmptyBackups";
import Loading from "Components/Loading";
import PageMeta from "Components/PageMeta";
import ActivityList from "../../../../../../common/components/ActivityList";
import EnvironmentBackupModal from "Containers/NavBar/Environment/BackupModal";

import * as S from "./Backups.styles";

const Backups = ({ environmentId, organizationId, projectId }) => {
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();
  const intl = useIntl();

  const project = useSelector(state =>
    state.project?.getIn(["data", organizationId, projectId])
  );
  const environment = useSelectorWithUrlParams(environmentSelector);
  const isLoadingActivities = useSelector(state =>
    state.activity?.getIn(["byEnvironment", "loading"], true)
  );
  const hasMoreActivities = useSelector(state =>
    state.activity.getIn(["byEnvironment", "hasMore"], false)
  );
  const activities = useSelector(state =>
    state.activity
      ?.getIn(
        ["byEnvironment", "data", organizationId, projectId, environmentId],
        new List()
      )
      .filter(elt =>
        [
          "environment.backup",
          "environment.restore",
          "environment.backup.delete"
        ].includes(elt.type)
      )
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      )
  );

  const loadActivity = () => {
    dispatch(
      loadEnvironmentActivities(projectId, environmentId, organizationId, [
        "snapshot"
      ])
    );
  };

  const loadMoreActivity = () => {
    dispatch(
      loadMoreEnvironmentActivities(projectId, environmentId, organizationId, [
        "snapshot"
      ])
    );
  };

  useEffect(loadActivity, [environmentId, projectId]);
  useEffect(() => {
    if (!environment)
      dispatch(loadEnvironment({ organizationId, projectId, environmentId }));
  }, []);

  return (
    <ContentLayout>
      <PageMeta title={`Backups | ${environment?.title} | ${project?.title}`} />

      <S.Wrapper className="environment-backups">
        <S.Heading>
          <Heading2>
            {intl.formatMessage({
              id: "environment.backups.title",
              defaultMessage: "Backups"
            })}
            <S.Info
              text={intl.formatMessage({
                id: "environment.backups.info"
              })}
              to={intl.formatMessage({
                id: "links.documentation.admin_backups"
              })}
              linkText={intl.formatMessage({
                id: "learnmore"
              })}
            />
          </Heading2>
        </S.Heading>

        {isLoadingActivities ? (
          <Loading />
        ) : (
          <S.List>
            {activities.size === 0 ? (
              <S.EmptyText className="empty-text">
                <div className="image-wrapper">
                  <EmptyBackups />
                </div>
                <Heading2>
                  {intl.formatMessage({
                    id: "environment.backups.empty.title",
                    defaultMessage: "No backups"
                  })}
                </Heading2>
                <p>
                  {intl.formatMessage({
                    id: "environment.backups.empty.details",
                    defaultMessage:
                      "You have no recent or running backups on this environment."
                  })}
                </p>
                <Button
                  mixed
                  onClick={() => setOpenModal(true)}
                  aria-label={intl.formatMessage({ id: "backup" })}
                >
                  <BackupIcon />{" "}
                  <span>{intl.formatMessage({ id: "backup" })}</span>
                </Button>
              </S.EmptyText>
            ) : (
              <ActivityList
                organizationId={organizationId}
                projectId={projectId}
                loadMore={loadMoreActivity}
                loadActivitiesOfType={loadActivity}
                activities={activities}
                activityType="snapshot"
                activityIsLoading={isLoadingActivities}
                hasMore={hasMoreActivities}
                canEditProject={
                  project &&
                  typeof project.hasPermission === "function" &&
                  project.hasPermission("#edit")
                }
                activityContext="backups"
              />
            )}
          </S.List>
        )}
      </S.Wrapper>

      <EnvironmentBackupModal
        isOpen={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </ContentLayout>
  );
};

Backups.propTypes = {
  projectId: PropTypes.string,
  environmentId: PropTypes.string,
  organizationId: PropTypes.string
};

export default Backups;
