import React from "react";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import countryList from "react-select-country-list";
import logger from "Libs/logger";

import { getCSSVarString, SECTION } from "Libs/themes";

import CardSection from "Components/CardSection";
import InputField from "Components/fields/InputField";
import CheckboxField from "Components/fields/CheckboxField";
import Description from "Components/fields/Description";
import Dropdown from "Components/Dropdown";
import Heading3 from "Components/styleguide/Heading3";
import Button from "Components/Button";
import client from "Libs/platform";
import { connect } from "react-redux";
import SepaSection from "./SepaSection";
import ModalBillingAdded from "./ModalBillingAdded";
import { vatCountries } from "Constants/vatCountries";

import * as S from "./styles";

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: true,
      number: "",
      address: {
        name_line: props.name,
        thoroughfare: "",
        premise: "",
        locality: "",
        administrative_area: "",
        postal_code: "",
        country: ""
      },
      addressProxy: {
        administrative_area: null,
        country: null,
        dependent_locality: null,
        locality: null,
        name_line: null,
        postal_code: null,
        premise: null,
        sub_administrative_area: null,
        sub_premise: null,
        thoroughfare: null
      },
      expiry: "",
      cvc: "",
      focused: "",
      goingForward: false,
      paymentSource: "credit-card",
      showVat: false,
      company_name: ""
    };

    this.onNumberChange = this.onNumberChange.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onExpiryChange = this.onExpiryChange.bind(this);
    this.onCvcChange = this.onCvcChange.bind(this);
    this.onCompanyNameChange = this.onCompanyNameChange.bind(this);
    this.onConsentChange = this.onConsentChange.bind(this);
    this.onCountryChange = this.onCountryChange.bind(this);
    this.goForward = this.goForward.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.isSepaSupported = this.isSepaSupported.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onVatNumberChange = this.onVatNumberChange.bind(this);
    this.edit = this.edit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.onThoroughfareChange = this.onThoroughfareChange.bind(this);
    this.onPremiseChange = this.onPremiseChange.bind(this);
    this.onLocalityChange = this.onLocalityChange.bind(this);
    this.onAdministrativeAreaChange =
      this.onAdministrativeAreaChange.bind(this);
    this.onPostalCodeChange = this.onPostalCodeChange.bind(this);
  }

  async componentDidMount() {
    const address = await client.getAddress(this.props.userId);
    const paymentTypes = await client.getPaymentSourcesAllowed();
    this.props.getPaymentSource();

    const { paymentSource } = this.props;
    const paymentSourceType = paymentSource.type || "credit-card";

    this.setState(prevState => ({
      ...prevState,
      paymentSourceType: paymentSourceType,
      currentPaymentSourceType: paymentSourceType,
      number: paymentSource.number,
      edit: !paymentSource.type,
      firstTime: !paymentSource.number,
      paymentTypes: paymentTypes?.source_types,
      address: {
        name_line: address.name_line,
        thoroughfare: address.thoroughfare,
        premise: address.premise,
        locality: address.locality,
        administrative_area: address.administrative_area,
        postal_code: address.postal_code,
        country: address.country
      },
      addressProxy: address,
      showVat: this.showVat(address.country),
      company_name: this.props.company_name
    }));
  }

  showVat = country => vatCountries.find(i => i === country);

  onNumberChange(e) {
    const number = e.target.value;
    this.setState({
      number: number,
      focused: "number"
    });
  }
  onNameChange(e) {
    const name_line = e.target.value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        name_line
      },
      focused: "name_line"
    }));
  }
  onVatNumberChange(e) {
    const vat_number = e.target.value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        vat_number
      },
      focused: "vat_number"
    }));
  }
  onCompanyNameChange(e) {
    const company_name = e.target.value;
    this.setState({
      company_name,
      focused: "company_name"
    });
  }
  onExpiryChange(e) {
    const expiry = e.target.value;
    this.setState({
      expiry: expiry,
      focused: "expiry"
    });
  }
  onCvcChange(e) {
    const cvc = e.target.value;
    this.setState({
      cvc: cvc,
      focused: "cvc"
    });
  }

  onCountryChange({ value }) {
    const country = value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        country
      },
      focused: "country",
      showVat: this.showVat(country)
    }));
  }

  onThoroughfareChange(e) {
    const thoroughfare = e.target.value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        thoroughfare
      },
      focused: "thoroughfare"
    }));
  }

  onPremiseChange(e) {
    const premise = e.target.value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        premise
      },
      focused: "premise"
    }));
  }

  onLocalityChange(e) {
    const locality = e.target.value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        locality
      },
      focused: "locality"
    }));
  }

  onAdministrativeAreaChange(e) {
    const administrative_area = e.target.value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        administrative_area
      },
      focused: "administrative_area"
    }));
  }

  onPostalCodeChange(e) {
    const postal_code = e.target.value;
    this.setState(prevState => ({
      address: {
        ...prevState.address,
        postal_code
      },
      focused: "postal_code"
    }));
  }

  onConsentChange(event) {
    this.setState({
      consent: event.target.checked,
      consentError: undefined
    });
  }

  goForward() {
    if (this.state.consent) {
      this.setState({ goingForward: true });
      const el = document.getElementById("stripe_cardsection_submit");
      if (el) return el.click();
      this.finalize();
    } else {
      this.setState({
        consentError: this.props.intl.formatMessage({ id: "check_consent" })
      });
    }
  }

  async finalize() {
    //this.props.goForward(this.state);
    const {
      address: {
        country,
        name_line,
        vat_number,
        locality,
        premise,
        administrative_area,
        thoroughfare,
        postal_code
      },
      addressProxy,
      company_name
    } = this.state;
    try {
      await addressProxy.update(
        {
          name_line,
          country,
          locality,
          premise,
          administrative_area,
          thoroughfare,
          postal_code
        },
        this.props.userId
      );
      await client.updateUserProfile(this.props.userId, {
        display_name: name_line,
        vat_number,
        company_name
      });
      this.setState(prevState => {
        const newState = prevState;

        newState.isModalOpen = prevState.firstTime;

        return newState;
      });
      this.props.finalize && this.props.finalize();
    } catch (err) {
      logger(err);
    }
  }

  onSuccess(data) {
    if (data) {
      this.setState({
        number: data.number,
        paymentSourceType: data.type,
        currentPaymentSourceType: data.type
      });
    }
    this.state.goingForward && this.finalize();
  }

  onTypeChange(paymentSourceType) {
    this.setState({
      edit: true,
      currentPaymentSourceType: paymentSourceType
    });
  }

  isSepaSupported() {
    const { paymentTypes = [] } = this.state;

    return paymentTypes.find(
      paymentType => paymentType.id === "stripe_sepa_debit"
    );
  }

  edit() {
    this.setState({
      edit: true
    });
  }

  cancel() {
    this.setState({
      edit: false
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false
    });
  }

  disableSubmitButton() {
    const {
      address: {
        name_line,
        country,
        thoroughfare,
        locality,
        administrative_area,
        postal_code
      }
    } = this.state;
    return !(
      name_line &&
      country &&
      thoroughfare &&
      locality &&
      administrative_area &&
      postal_code
    );
  }

  render() {
    const {
      intl,
      goForwardText,
      goBack,
      goBackText,
      hasVoucher,
      estimate,
      loadingPaymentSource
    } = this.props;
    const {
      address: {
        name_line,
        country,
        vat_number,
        thoroughfare,
        locality,
        premise,
        administrative_area,
        postal_code
      },
      currentPaymentSourceType,
      paymentSourceType,
      number,
      edit,
      isModalOpen,
      showVat,
      company_name
    } = this.state;

    const PaymentComponent =
      currentPaymentSourceType === "stripe_sepa_debit"
        ? SepaSection
        : CardSection;

    const defaultCountry = countryList()
      .getData()
      .find(i => i.value === country);

    return (
      <S.Wrapper>
        <ModalBillingAdded isOpen={isModalOpen} closeModal={this.closeModal} />

        <S.PaymentWrapper>
          <S.CardWrapper className={`card-form-${currentPaymentSourceType}`}>
            <Heading3>{intl.formatMessage({ id: "payment_method" })}</Heading3>
            {this.isSepaSupported() && (
              <S.Tabs>
                <S.Tab
                  active={currentPaymentSourceType === "credit-card"}
                  onClick={() => this.onTypeChange("credit-card")}
                >
                  {intl.formatMessage({ id: "tabs.credit_card" })}
                </S.Tab>
                <S.Tab
                  active={currentPaymentSourceType === "stripe_sepa_debit"}
                  onClick={() => this.onTypeChange("stripe_sepa_debit")}
                >
                  {intl.formatMessage({ id: "tabs.sepa" })}
                </S.Tab>
              </S.Tabs>
            )}
            {!loadingPaymentSource && (
              <>
                {currentPaymentSourceType !== "stripe_sepa_debit" && (
                  <Description
                    color={"var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)))"}
                  >
                    {intl.formatMessage({
                      id: "payment_information_description"
                    })}
                  </Description>
                )}
                <PaymentComponent
                  edit={edit || paymentSourceType !== currentPaymentSourceType}
                  onEdit={this.edit}
                  onCancel={this.cancel}
                  hideButtons={false}
                  onSuccess={this.onSuccess}
                  name={name_line}
                  number={number}
                />
              </>
            )}
            {hasVoucher && (
              <React.Fragment>
                <Description
                  color={"var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)))"}
                >
                  {intl.formatMessage({ id: "payment_voucher_description" })}
                </Description>
                <S.Voucher>
                  {estimate && estimate.credit && estimate.credit.formatted}
                </S.Voucher>

                <S.VoucherLabel>
                  {intl.formatMessage({ id: "voucher_credit" })}
                </S.VoucherLabel>
              </React.Fragment>
            )}
          </S.CardWrapper>
          <Heading3>
            {intl.formatMessage({ id: "billing_information" })}
          </Heading3>
          <S.AddressWrapper id="billing-address-form" className="address-form">
            <InputField
              id="name_line"
              name="name_line"
              label={intl.formatMessage({ id: "full_name" })}
              placeholder={intl.formatMessage({ id: "full_name" })}
              className="name_line"
              value={name_line}
              onChange={this.onNameChange}
            />
            <Dropdown
              className="field-country"
              options={countryList().getData()}
              name="country"
              onChange={this.onCountryChange}
              searchable
              value={defaultCountry}
              defaultValue={{ label: defaultCountry, value: defaultCountry }}
              clearable={false}
              label={intl.formatMessage({ id: "select_country" })}
              placeholder={intl.formatMessage({ id: "select_country" })}
              fieldType={true}
              required={true}
            />
            <InputField
              id="company_name"
              name="company_name"
              label={intl.formatMessage({ id: "company" })}
              placeholder={intl.formatMessage({ id: "company_name" })}
              value={company_name}
              onChange={this.onCompanyNameChange}
              required={false}
            />
            {showVat(country) && (
              <InputField
                id="vat_number"
                name="vat_number"
                label={intl.formatMessage({ id: "vat_number" })}
                placeholder={intl.formatMessage({ id: "vat_number" })}
                value={vat_number}
                onChange={this.onVatNumberChange}
                required={false}
              />
            )}
            <InputField
              id="thoroughfare"
              name="thoroughfare"
              label={intl.formatMessage({ id: "address" })}
              placeholder={intl.formatMessage({ id: "address" })}
              className="address"
              value={thoroughfare}
              onChange={this.onThoroughfareChange}
              required={true}
            />
            <InputField
              id="premise"
              name="premise"
              label={intl.formatMessage({ id: "address2" })}
              placeholder={intl.formatMessage({ id: "address2" })}
              className="address"
              value={premise}
              onChange={this.onPremiseChange}
              required={false}
            />
            <InputField
              id="locality"
              name="locality"
              label={intl.formatMessage({ id: "city" })}
              placeholder={intl.formatMessage({ id: "city" })}
              className="locality"
              value={locality}
              onChange={this.onLocalityChange}
            />
            <S.InlineFields>
              <InputField
                id="administrative_area"
                name="administrative_area"
                label={intl.formatMessage({
                  id: "state_province_region"
                })}
                placeholder={intl.formatMessage({
                  id: "state_province_region.placeholder"
                })}
                className="administrative_area"
                value={administrative_area}
                onChange={this.onAdministrativeAreaChange}
              />
              <InputField
                id="postal_code"
                name="postal_code"
                label={intl.formatMessage({ id: "zip_postal_code" })}
                placeholder={intl.formatMessage({
                  id: "zip_postal_code.placeholder"
                })}
                className="postal_code"
                value={postal_code}
                onChange={this.onPostalCodeChange}
              />
            </S.InlineFields>
          </S.AddressWrapper>
          <div>
            <CheckboxField
              onChange={this.onConsentChange}
              value={this.state.consent}
              error={this.state.consentError}
              label={intl.formatMessage({ id: "payment_consent" })}
              forId="consent"
            />
          </div>
        </S.PaymentWrapper>
        <div className="action-buttons-wrapper">
          <div className="action-buttons">
            {goBack && (
              <Button
                id="create-project-back"
                type="button"
                className="secondary"
                onClick={goBack}
                aria-label={goBackText}
              >
                {goBackText}
              </Button>
            )}
            <Button
              id="create-project-submit"
              type="button"
              className="primary"
              onClick={this.goForward}
              aria-label={goForwardText}
              disabled={this.disableSubmitButton()}
            >
              {goForwardText}
            </Button>
          </div>
        </div>
      </S.Wrapper>
    );
  }
}

PaymentForm.propTypes = {
  title: PropTypes.string,
  goBackText: PropTypes.string,
  goForwardText: PropTypes.string,
  errors: PropTypes.object,
  intl: PropTypes.object,
  goForward: PropTypes.func,
  goBack: PropTypes.func,
  name: PropTypes.string,
  userId: PropTypes.string,
  estimate: PropTypes.object,
  showCardForm: PropTypes.bool,
  hasVoucher: PropTypes.bool,
  theme: PropTypes.object,
  finalize: PropTypes.func,
  company_name: PropTypes.string,
  getPaymentSource: PropTypes.func,
  loadingPaymentSource: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  paymentSource: PropTypes.object
};

const mapStateToProps = state => {
  const userId = state.app && state.app.getIn(["me", "id"]);
  return {
    userId,
    company_name: state.profile.getIn(["data", userId])?.company_name,
    loadingPaymentSource: ["idle", true].includes(
      state.paymentSource?.get("loading")
    ),
    paymentSource: state.paymentSource?.get("data")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPaymentSource: () =>
      import("Reducers/paymentSource").then(reducer =>
        dispatch(reducer.getPaymentSource())
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withTheme(PaymentForm)));
