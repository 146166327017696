import styled from "styled-components";

import { css, SECTION, HEADER, LINK, getCSSVarString } from "Libs/themes";

export const Line = styled.div`
  border-radius: 2px;
  display: flex;
  color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
  font-size: 15px;
  line-height: 24px;
  padding: 8px 16px;
  margin: 2px 0;
  > :first-child {
    color:var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
    font-weight: bold;
    flex: 1 0;
    &.no-invoices {
      font-weight: 600;
    }
  }
  &.no-invoices {
    text-align: center;
    margin: 0;
    padding-bottom: 40px;
  }
  &.billing-period {
    padding: 0 0 8px;
    margin: 0 0 16px 0;
    border-bottom: 1px
      var(--section-periwinkle-grey-border-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
    a {
      color:var(--link-skye-color,var(--link-skye,var(--skye)));
      font-weight: 600;
      text-decoration: none;
    }
  }
  &.trial {
    background: #d6f6ef;
  }
  &.subtotal-discount,
  &.base_price {
    background-color:var(--section-ice-background-color,var(--section-ice,var(--ice)));
  }
  &.empty {
    color:var(--header-granite-color,var(--header-granite,var(--granite)));
    font-weight: 600;
    > :first-child {
      color:var(--header-granite-color,var(--header-granite,var(--granite)));
    }
  }
  &.total-row {
    background-color:var(--section-skye-light-background-color,var(--section-skye-light,var(--skye-light)));
    margin: 6px 0 0;
    padding: 16px;
    flex-direction: column;
    > :first-child {
      flex: 0 0;
    }
    h2 {
      line-height: 33px;
      margin-bottom: 0;
      .total-currency {
        margin-left: 7px;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color:var(--header-granite-color,var(--header-granite,var(--granite)));
      }
    }
    > span {
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color:var(--section-slate-color,var(--section-slate,var(--slate)));
    }
  }
`;
