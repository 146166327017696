import styled from "styled-components";
import { css, SECTION, getCSSVarString, MODAL } from "Libs/themes";

export const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Wrapper = styled.div`
  background-color:var(--modal-snow-background-color,var(--modal-snow,var(--snow)));
  border-radius: 2px;
  color:var(--modal-night-color,var(--modal-night,var(--night)));
  z-index: 9;
  padding: 32px 32px 0 32px;
  box-sizing: border-box;
  width: 100%;
  min-height: 570px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  top: -30px;
  margin: 0 auto;
  left: 0;
  right: 0;

  .main {
    background: transparent;
  }
  .search-wrapper {
    height: auto;
    input.search {
      var(--section-ebony-light-color,var(--section-ebony-light,var(--ebony-light)));
      &::placeholder {
        color: #98a0ab;
      }
    }
  }
  .filter-tags,
  .select-region,
  .field-country {
    .select-box__menu {
      box-shadow: 0 6px 24px rgba(75, 97, 128, 0.32);
    }
  }
  .filter-tags {
    .dropdown {
      margin-bottom: 0;
    }
    .select-box {
      width: 135px;
      .select-box__value-container {
        .select-box__single-value {
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
    &.filter-type {
      .select-box {
        width: 108px;
      }
      .select-box__single-value {
        width: calc(100% - 22px);
      }
      .select-box__menu {
        width: 135px;
      }
    }
  }
  .close {
    position: absolute;
    top: 32px;
    right: 32px;
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;
