import React, { useState } from "react";
import { useIntl } from "react-intl";
import { IbanElement, injectStripe } from "react-stripe-elements";
import PropTypes from "prop-types";
import { LiveMessage } from "react-aria-live";

import { getCSSVarString, INPUT, ICON } from "Libs/themes";
import client from "Libs/platform";
import logger from "Libs/logger";

import Button from "Components/Button";
import InputField from "Components/fields/InputField";
import Label from "Components/fields/Label";
import Loading from "Components/Loading";
import RequiredTag from "Components/fields/RequiredTag";
import InfoDialog from "Components/InfoDialog";
import Error from "Components/Error";

import { Layout, Disclaimer, NewCardWrapper } from "./SepaSection.styles";
import { withTheme } from "styled-components";

const SepaSection = ({
  edit,
  name_line,
  email,
  hideButtons,
  number,
  onEdit,
  onCancel,
  onSuccess,
  organizationId,
  stripe
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(name_line);
  const [nameError, setNameError] = useState(false);
  const [billingEmail, setBillingEmail] = useState(email);
  const [emailError, setEmailError] = useState(false);
  const [error, setError] = useState(null);

  const intl = useIntl();

  const handleSubmit = async ev => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    if (!billingEmail) {
      setEmailError("Email required");
      return;
    }

    if (!name) {
      setNameError("Name required");
      return;
    }

    if (edit) {
      setIsLoading(true);

      try {
        const sourceData = {
          type: "sepa_debit",
          // Hardcoded currency -- SEPA transactions must be in EUR.
          currency: "eur",
          owner: {
            name,
            email: billingEmail
          },
          mandate: {
            // Automatically send a mandate notification email to your customer
            // once the source is charged.
            notification_method: "email"
          }
        };

        const response = await stripe.createSource(sourceData);

        if (response?.error?.message) {
          setError(response.error.message);
          throw response.error;
        } else {
          setError(null);
        }

        // organizationId is the organization.id here
        const data = await client.addOrganizationPaymentSource(
          organizationId,
          "stripe_sepa_debit",
          response?.source?.id
        );

        setIsLoading(false);

        onSuccess && onSuccess(data);
      } catch (err) {
        if (![404, 403, 400].includes(err.code)) {
          logger(err);
        }
        setIsLoading(false);
        setError(err);
      }
    } else {
      onSuccess && onSuccess();
    }
  };

  const onNameChange = e => {
    setName(e.target.value);
  };

  return (
    <Layout>
      {isLoading && <Loading />}
      {error && (
        <Error>
          {error.message || error.description || JSON.stringify(error, null, 4)}
        </Error>
      )}
      <Disclaimer>
        <InfoDialog
          title={intl.formatMessage({ id: "bank_account_payments" })}
          text={intl.formatMessage({ id: "sepa_disclamer" })}
        />
        <span>{intl.formatMessage({ id: "sepa_disclamer_short" })}</span>
      </Disclaimer>
      <form aria-labelledby="edit-card" id="stripe-payment-form">
        {!edit && number ? (
          <>
            <LiveMessage
              message="click update bank accounts to edit"
              aria-live="polite"
            />
            <InputField
              label={intl.formatMessage({ id: "bank_account_number" })}
              className="card-number"
              isDisabled={true}
              value={number && number.replace(/X/g, "•").replace(/-/g, " ")}
            />
            <div>
              <Button
                onClick={onEdit}
                type="button"
                aria-label={intl.formatMessage({ id: "update_bank_account" })}
              >
                {intl.formatMessage({ id: "update_bank_account" })}
              </Button>
            </div>
          </>
        ) : (
          <NewCardWrapper className="new-card">
            <LiveMessage
              message={intl.formatMessage({ id: "bank_account_payments" })}
              aria-live="polite"
            />
            <InputField
              id="name_line"
              name="name_line"
              label={intl.formatMessage({ id: "full_name" })}
              placeholder={intl.formatMessage({ id: "full_name" })}
              className="name_line"
              value={name}
              onChange={onNameChange}
              error={nameError}
            />
            <InputField
              id="email"
              name="email"
              label={intl.formatMessage({ id: "email" })}
              placeholder={intl.formatMessage({ id: "email" })}
              className="email"
              value={billingEmail}
              onChange={e => setBillingEmail(e.target.value)}
              error={emailError}
            />
            <div>
              <Label>
                {intl.formatMessage({ id: "bank_account_number" })}{" "}
                <RequiredTag />
              </Label>
              <IbanElement
                supportedCountries={["SEPA"]}
                className="stripe-card"
                style={{
                  base: {
                    backgroundColor: getCSSVarString(
                      INPUT,
                      "ice",
                      "background-color"
                    ),
                    fontSize: "14px",
                    iconColor:"var(--icon-night-color,var(--icon-night,var(--night)))",
                    color:"var(--input-night-color,var(--input-night,var(--night)))",
                    "::placeholder": {
                      fontSize: "14px",
                      color:"var(--input-night-color,var(--input-night,var(--night)))"
                    }
                  }
                }}
                iconStyle="solid"
              />
            </div>
            {!hideButtons && (
              <>
                <Button
                  type="button"
                  aria-label={intl.formatMessage({ id: "save_changes" })}
                  id="stripe_cardsection_submit"
                  className="primary"
                  onClick={handleSubmit}
                  disabled={!billingEmail || !name}
                >
                  {intl.formatMessage({ id: "save_changes" })}
                </Button>
                {number && (
                  <Button
                    type="button"
                    aria-label={intl.formatMessage({ id: "save_changes" })}
                    id="stripe_cardsection_cancel"
                    className="secondary"
                    onClick={onCancel}
                  >
                    {"cancel"}
                  </Button>
                )}
              </>
            )}
          </NewCardWrapper>
        )}
      </form>
    </Layout>
  );
};

SepaSection.propTypes = {
  email: PropTypes.string,
  name_line: PropTypes.string,
  number: PropTypes.string,
  organizationId: PropTypes.string,
  edit: PropTypes.bool,
  hideButtons: PropTypes.bool,
  stripe: PropTypes.object,
  intl: PropTypes.object,
  onSuccess: PropTypes.func,
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  theme: PropTypes.object
};

export default withTheme(injectStripe(SepaSection));
