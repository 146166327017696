import * as d3 from "d3";

const applyBrush = (x, y, onBrushEnd, onBrushStart, onBrushMove) => {
  const [x1, x2] = x.range();
  const [y1, y2] = y.range();

  const brush = d3.brushX().extent([
    [x1, y2],
    [x2, y1]
  ]);

  brush.on("brush", onBrushMove);
  brush.on("start", onBrushStart);
  brush.on("end", ({ selection }) => {
    if (selection) {
      onBrushEnd(x.invert(selection[0]), x.invert(selection[1]));
      brush.clear(d3.select(this));
    }
  });

  return brush;
};

export default applyBrush;
