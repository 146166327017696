import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import Heading2 from "Components/styleguide/Heading2";
import PageDescription from "Components/PageDescription";
import BackLink from "Components/BackLink";
import Error from "Components/Error";

import SetupTFA from "../../components/SetupTFA";
import Providers from "../../components/Providers";
import { enroll, setup } from "Reducers/organization/settings/security";

const FlexibleHeading2 = styled(Heading2)`
  font-size: 18px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

const Setup = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userId = useSelector(({ app }) => app?.getIn(["me", "id"]));
  const tfa = useSelector(({ security }) => security.getIn(["setup", "data"]));
  const recoveryCodes = useSelector(({ security }) =>
    security.getIn(["recoveryCodes", "data"])
  );
  const isLoadingRecoveryCodes = useSelector(({ security }) =>
    security.getIn(["recoveryCodes", "isLoading"])
  );
  const isLoadingTfa = useSelector(({ security }) =>
    security.getIn(["setup", "isLoading"])
  );

  const setupError = useSelector(({ security }) =>
    security.getIn(["setup", "error"])
  );

  const recoveryCodesError = useSelector(({ security }) =>
    security.getIn(["recoveryCodes", "error"])
  );

  useEffect(() => {
    if (userId) {
      dispatch(setup(userId));
    }
  }, [userId]);

  useEffect(() => {
    if (recoveryCodes?.length) {
      history.push("../security");
    }
  }, [recoveryCodes]);

  const onVerify = verificationCode =>
    dispatch(enroll(userId, tfa?.secret, verificationCode));

  return (
    <>
      <BackLink to="../security" />
      <FlexibleHeading2>
        <FormattedMessage id="security.setup.heading" />
      </FlexibleHeading2>
      <PageDescription>
        <FormattedMessage id="security.setup.description" />
      </PageDescription>
      <Providers />
      {setupError ? (
        <Error>{setupError}</Error>
      ) : (
        <SetupTFA
          isLoading={isLoadingTfa || isLoadingRecoveryCodes}
          secret={tfa?.secret}
          qrCode={tfa?.qr_code}
          onVerify={onVerify}
          error={recoveryCodesError}
        />
      )}
    </>
  );
};

export default Setup;
