import styled from "styled-components";
import { css, LINK, SECTION } from "Libs/themes";

import Button from "UI/Button";
import Heading4 from "Components/styleguide/Heading4";
import InputField from "Components/fields/InputField";

export const Wrapper = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0;
  &.add-form {
    background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));
    border-radius: 0 0 4px 4px;
    margin-bottom: 16px;
  }
`;

export const FormWrapper = styled.form`
  padding: 32px;
  min-height: 200px;
  width: 100%;
`;

export const DefaultButton = styled(Button)`
  margin-right: 16px;
`;

export const DeleteButton = styled(Button)`
  margin-left: auto !important;
`;

export const InputWrapper = styled.div`
  position: relative;
  max-width: 264px;
  width: 100%;
`;

export const Note = styled.dl`
  margin: 0 0 24px 0;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  color:var(--section-slate-color,var(--section-slate,var(--slate)));

  dt {
    margin-bottom: 5px;
  }
  dd {
    margin: 0;
    padding: 0;
    &:before {
      content: "• ";
      margin: 0 0 0 5px;
    }
  }
`;

export const Config = styled.div`
  ${Heading4} {
    margin: 0 0 8px 0;
    color:var(--section-ebony-color,var(--section-ebony,var(--ebony)));
    font-weight: bold;
    text-transform: none;
  }

  p {
    margin: 8px 0 0 0;
    font-size: 14px;
    line-height: 24px;
    color:var(--section-slate-color,var(--section-slate,var(--slate)));

    a {
      position: relative;
      text-decoration: none;
      &::after {
        transition: background-color 0.25s;
        content: "";
        position: absolute;
        top: 19.17px;
        left: 0px;
        height: 1px;
        width: calc(100% - 0px);
        background-color: transparent;
      }
      &:hover::after {
        background-color:var(--link-skye-background-color,var(--link-skye,var(--skye)));
      }
    }
  }
`;

export const CopyWrapper = styled.div`
  max-width: 800px;
  margin: 24px 0 40px 0;
`;

export const CopyLabel = styled.div`
  margin: 0 0 8px 0;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color:var(--section-slate-color,var(--section-slate,var(--slate)));
`;

export const Input = styled(InputField)`
  width: 100%;
`;
