import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Button from "UI/Button";

import * as S from "./Errors.styles";

const EmptyMetrics = () => {
  const intl = useIntl();

  return (
    <S.Layout>
      <S.Illustration />
      <S.H3>
        <FormattedMessage id="metrics.error.empty.header" />
      </S.H3>
      <S.Paragraph>
        <FormattedMessage id="metrics.error.empty.paragraph" />
      </S.Paragraph>
      <Button
        variant="link"
        as="a"
        target="_blank"
        href={intl.formatMessage({
          id: "links.documentation.redeploy"
        })}
      >
        <FormattedMessage id="learnmore" />
      </Button>
    </S.Layout>
  );
};

export default EmptyMetrics;
