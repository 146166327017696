import styled, { css as styledCSS } from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION } from "Libs/themes";

import Flag from "Components/Flag";
import Add from "Components/ButtonAdd";
import Button from "Components/Button";
import InlineBanner from "Components/InlineBanner";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  .settings-item .setting-line .action .open-text:not(:empty) {
    min-width: 40px;
  }
`;

export const InfoLayout = styled.div`
  display: flex;
  align-items: center;
  .user {
    width: 75%;
    display: flex;
    flex-align: center;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .display-name {
      font-weight: bold;
      font-size: 16px;
      width: 50%;
    }
    .email {
      font-weight: normal;
      font-size: 15px;
    }
  }
  .role {
    display: inline-block;
    align-items: center;
    font-size: 13px;
    color:var(--section-slate-color,var(--section-slate,var(--slate)));
    white-space: nowrap;
    ${semiBoldAlias};
    &:first-letter {
      text-transform: uppercase;
    }
    &.super-user {
      display: inline-flex;
    }
  }
  .lock-icon {
    height: 24px;
    width: 24px;
    min-width: 24px;
    display: inline-flex;
    align-items: stretch;
    margin-left: 16px;
    svg {
      width: 100%;
    }
  }
`;

export const RedeployWarning = styled(Flag).attrs({
  level: "warning",
  singleLine: true
})`
  max-width: 100%;
  box-sizing: border-box;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 24px;
  margin-top: 0;
  padding: 12px;
`;

export const AddLink = styled(Add)`
  float: right;
  ${props =>
    props.isWarningVisible &&
    styledCSS`
      position: absolute;
      top: -49px;
      right: 0;
    `};
`;

export const Code = styled.code`
  font-weight: normal;
  line-height: 24px;
  font-size: 12px;
`;

export const RedeployButton = styled(Button).attrs({ as: Link })`
  text-decoration: none;
  margin-right: 16px;
  padding-left: 8px;
  padding-right: 16px;

  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  :active {
    margin-right: 16px;
  }

  svg {
    margin-right: 8px;
  }
`;

export const EnvironmentTypeBanner = styled(InlineBanner)`
  padding: 16px;
`;
