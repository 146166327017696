import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import Button from "UI/Button";

import CopyableArea from "Components/CopyableArea";
import Heading2 from "Components/styleguide/Heading2";
import InfoDialog from "Components/InfoDialog";

import * as Specificities from "../Specificities";

import * as S from "./Details.styles";

const Details = ({ integration }) => {
  const intl = useIntl();
  const location = useLocation();

  const getSpecificities = () => {
    const { category } = integration;
    const k = category.charAt(0).toUpperCase() + category.slice(1);
    const Comp = Specificities[k];
    if (!Comp) return false;
    return <Comp integration={integration} />;
  };

  const validateCmd = `${intl.formatMessage({
    id: "CLI_command_name"
  })} integration:validate ${integration.id}`;

  return (
    <S.Wrapper>
      <S.Header>
        <Heading2 style={{ marginBottom: 22 }}>
          {intl.formatMessage({
            id: "integration.title",
            defaultMessage: "Details"
          })}{" "}
          <InfoDialog
            title={intl.formatMessage({
              id: "integration.info.title",
              defaultMessage: "Integrations Settings"
            })}
            text={intl.formatMessage({
              id: "integration.info.content",
              defaultMessage:
                "You can customize your integrations through our Command Line Interface (CLI)."
            })}
            to={intl.formatMessage({
              id: "links.documentation.integrations"
            })}
            linkText={intl.formatMessage({
              id: "integration.info.link",
              defaultMessage: "Learn more"
            })}
          />
        </Heading2>
        <Button
          as={Link}
          variant="tertiary"
          to={`${location.pathname}/configure`}
        >
          {intl.formatMessage({
            id: "edit",
            defaultMessage: "Edit"
          })}
        </Button>
      </S.Header>

      {integration._links["#validate"] && (
        <S.Cmds>
          <article>
            <label>
              {intl.formatMessage({
                id: "integration.verify",
                defaultMessage: "Verify integration"
              })}
            </label>
            <CopyableArea
              id="integration-cmd-verify"
              content={validateCmd}
              singleLine={true}
            >
              {validateCmd}
            </CopyableArea>
          </article>
        </S.Cmds>
      )}

      {getSpecificities()}
    </S.Wrapper>
  );
};

Details.propTypes = {
  integration: PropTypes.object
};

export default Details;
