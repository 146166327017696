import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, BUTTON, SECTION } from "Libs/themes";

import CloseBtn from "Components/CloseBtn";

export const Wrapper = styled.aside`
  box-sizing: border-box;
  padding: 0 22px 32px 32px;
  background-color:var(--section-ice-background-color,var(--section-ice,var(--ice)));

  h2 {
    margin: 68px 0 16px 0;
    line-height: 33px;
  }

  @media (min-width: 1280px) {
    width: 290px;
    min-width: 290px;
  }
`;

export const WrapperEmbedded = styled.div`
  padding: 0;
`;

export const Close = styled(CloseBtn)`
  border: 1px solid red;
  position: absolute;
  top: 30px;
  right: 24px;
`;

export const Step = styled.button.attrs({
  type: "button"
})`
  border: 1px solid transparent;
  background-color: transparent;
  ${semiBoldAlias};
  font-size: 14px;
  padding: 0;
  margin-left: 0 !important;
  margin-bottom: 8px;
  color:var(--button-night-color,var(--button-night,var(--night)));
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
  cursor: pointer;
  &:hover,
  &:active {
    color:var(--button-skye-color,var(--button-skye,var(--skye)));
    outline: none;
  }
`;

export const Optional = styled.span`
  font-size: 12px;
  font-weight: normal;
  color:var(--section-granite-color,var(--section-granite,var(--granite)));
`;

export const Ellipsis = styled.div`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
