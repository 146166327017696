import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import Button from "UI/Button";
import { capitalize } from "Libs/utils";

import InputField from "Components/fields/InputField";

import ImageUpload from "../ImageUpload";

import {
  Aside,
  ButtonWrapper,
  Header,
  HelpText,
  PageTitle,
  UserName
} from "./BasicFormSettings.styles";
import { userDetailFields } from "./options";
import { useUserPicture } from "./useUserPicture";
import Accounts from "../accounts/Accounts";

const BasicFormSettingsAside = props => {
  const { formatMessage: t } = useIntl();

  const initialFormValues = {
    first_name: props.first_name,
    last_name: props.last_name,
    username: props.username,
    email: props.resetEmail || props.email,
    website: props.website,
    company: props.company,
    picture: props.picture
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [changedValues, setChangedValues] = useState({});
  const userPicture = useUserPicture(formValues.picture);

  useEffect(() => {
    const updatedFormValues = {
      first_name: props.first_name,
      last_name: props.last_name,
      username: props.username,
      email: props.emailReset || props.email,
      website: props.website,
      company: props.company,
      picture: props.picture
    };
    setFormValues(updatedFormValues);
  }, [props.username, props.picture, props.cacheKey]);

  const onFieldChange = (value, fieldName) => {
    const fieldUpdates = {
      ...changedValues,
      [fieldName]: value
    };
    const updatedFormValues = {
      ...formValues,
      ...fieldUpdates
    };
    setFormValues(updatedFormValues);
    setChangedValues(fieldUpdates);
  };

  const onSave = () => {
    props.onSave(changedValues);

    setChangedValues({});
  };

  const onPictureChange = picture => {
    if (picture.size > 1900000) {
      props.onPictureError(
        {
          message: "exceeding the maximum file size"
        },
        picture
      );
    } else {
      onFieldChange(picture, "picture");
    }
  };

  const onCancel = () => {
    setFormValues(initialFormValues);
    setChangedValues({});
  };

  const isChanged = Object.keys(changedValues).length > 0;

  return (
    <Aside>
      <Header>
        <PageTitle>{t({ id: "menu.my_profile" })}</PageTitle>
        <ImageUpload
          picture={userPicture}
          username={props.username}
          onChange={onPictureChange}
        />
        <UserName>{props.first_name + " " + props.last_name}</UserName>
        <Accounts />
      </Header>

      <h2>{t({ id: "user_details" })}</h2>

      {userDetailFields.map(value => (
        <InputField
          width="320px"
          onChange={e => onFieldChange(e.target.value, value)}
          key={value}
          id={`organization-${value}`}
          label={t({ id: value })}
          value={formValues[value]}
        />
      ))}
      {props.emailReset && (
        <HelpText data-test="email-reset-help">
          {t(
            { id: "reset_email_message" },
            {
              cd: str => <code>{str}</code>, // eslint-disable-line
              emailAddress: props.emailReset
            }
          )}
        </HelpText>
      )}
      <Button
        variant="link"
        as="a"
        href={`${process.env.AUTH_URL}/reset-password`}
        id="image-upload-button"
        style={{ width: "max-content", marginTop: "8px" }}
      >
        {t({ id: "reset_password" })}
      </Button>
      {isChanged && (
        <ButtonWrapper>
          <Button
            id="save_organization_general_settings_button"
            className="primary"
            onClick={onSave}
            aria-label={t({ id: "save" })}
          >
            {capitalize(t({ id: "save" }))}
          </Button>
          <Button
            id="cancel-organization-name-btn"
            onClick={onCancel}
            variant="secondary"
            aria-label={t({ id: "cancel" })}
          >
            {capitalize(t({ id: "cancel" }))}
          </Button>
        </ButtonWrapper>
      )}
    </Aside>
  );
};

// Prop keys for the form need to match
// what they are in the user object from the API
// ex: first_name and not firstName. This
// makes it easier to handling saving the form data.
BasicFormSettingsAside.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  username: PropTypes.string,
  email: PropTypes.string,
  company: PropTypes.string,
  website: PropTypes.string,
  onSave: PropTypes.func,
  emailReset: PropTypes.string,
  cacheKey: PropTypes.string,
  resetEmail: PropTypes.string,
  picture: PropTypes.string,
  onPictureError: PropTypes.func
};

export default BasicFormSettingsAside;
