import styled, { css as styledCSS } from "styled-components";
import { css, SECTION, ICON, HEADER } from "Libs/themes";
import Chart from "../../components/Chart";
import { Button } from "@platformsh/ui-kit";

export const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

export const WorkerBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShareButton = styled(Button)`
  svg {
    color:var(--icon-granite-color,var(--icon-granite,var(--granite)));
  }

  :hover svg {
    color:var(--icon-slate-color,var(--icon-slate,var(--slate)));
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const Layout = styled.div`
  padding-top: 20px;
  padding-right: 32px;
  padding-bottom: 20px;
  padding-left: 32px;
  border-radius: 2px;
  background-color:var(--section-snow-background-color,var(--section-snow,var(--snow)));

  ${({ isOpen }) =>
    isOpen &&
    styledCSS`
      margin-top: 8px;
    `};
`;

export const ChartLayout = styled(Layout)`
  padding-top: 24px;
  margin-bottom: 8px;
  margin-top: -4px;
`;

export const Title = styled.div`
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${({ isOpen }) =>
    !isOpen &&
    styledCSS`
      margin-bottom: 22px;
    `};
`;

export const Toggle = styled.button`
  appearance: none;
  background-color: transparent;
  text-decoration: none;
  color:var(--section-skye-color,var(--section-skye,var(--skye)));
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  cursor: pointer;
  padding: 0;

  :hover {
    text-decoration: underline;
  }
`;

const __verticalSeparator = (separator = 16) => styledCSS`
  position: relative;
  :before {
    left: -${separator}px;
    position: absolute;
    content: "";
    height: 100%;
    width: 1px;
    background-color:var(--section-periwinkle-grey-background-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
  }
`;

export const Grid = styled.div`
  display: grid;
  row-gap: 24px;
  grid-template-columns: minmax(0, 1fr);
  overflow: hidden;

  // The last row of elements still have the separator, however,
  // it can't be seen because the overflow of the parent is hidden
  > :nth-child(1n):not(:last-child) {
    position: relative;

    :after {
      content: "";
      position: absolute;
      bottom: -13px;
      left: 0;
      background-color:var(--section-periwinkle-grey-background-color,var(--section-periwinkle-grey,var(--periwinkle-grey)));
      height: 1px;
      width: calc(100% + 64px);
    }
  }

  @media (min-width: 890px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 32px;
  }

  @media (min-width: 890px) and (max-width: 1199px) {
    > :nth-child(2n) {
      ${__verticalSeparator()};
    }
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (min-width: 1200px) and (max-width: 1500px) {
    > :nth-child(3n + 2),
    > :nth-child(3n + 3) {
      ${__verticalSeparator()};
    }
  }

  @media (min-width: 1500px) {
    column-gap: 64px;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    > :nth-child(3n + 2),
    > :nth-child(3n + 3) {
      ${__verticalSeparator(32)};
    }
  }
`;

export const GridLG = styled(Grid)`
  gap: 64px;

  > :nth-child(1n):not(:last-child):after {
    width: calc(100% + 64px);
    bottom: -32px;
  }

  @media (min-width: 890px) {
    column-gap: 64px;
  }

  @media (min-width: 890px) and (max-width: 1199px) {
    > :nth-child(2n) {
      ${__verticalSeparator(32)};
    }
  }

  @media (min-width: 1200px) and (max-width: 1500px) {
    > :nth-child(3n + 2),
    > :nth-child(3n + 3) {
      ${__verticalSeparator(32)};
    }
  }

  @media (min-width: 1500px) {
    column-gap: 64px;

    > :nth-child(3n + 2),
    > :nth-child(3n + 3) {
      ${__verticalSeparator(32)};
    }
  }
`;

export const ContainerChart = styled(Chart)`
  margin-left: -32px;
  margin-right: -32px;
`;
