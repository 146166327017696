import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";

import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import { environmentLoadingSelector } from "Reducers/environment";

import ModalWrapper from "Components/Modal";
import Button from "UI/Button";
import ContentPaneLayout from "Components/ContentPaneLayout";
import Loading from "Components/Loading";
import Error from "Components/Error";
import CopyableArea from "Components/CopyableArea";
import ButtonWrapper from "Components/ButtonWrapper";
import Heading4 from "Components/styleguide/Heading4";
import InfoDialog from "Components/InfoDialog";

import { goToEnvironment, capitalize } from "Libs/utils";
import { redeploy, clearRedeploy } from "Reducers/environment/actions/redeploy";

import { Note } from "./styles";

const EnvironmentSyncPane = ({
  push,
  organizationId,
  projectId,
  environmentId
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isRedirecting, setIsRedirecting] = useState(false);

  const { isLoading, error, data } = useSelector(({ redeploy }) => ({
    isLoading: redeploy?.get("isLoading"),
    error: redeploy?.get("error"),
    data: redeploy?.get("data")
  }));
  const isEnvironmentLoading = useSelectorWithUrlParams(
    environmentLoadingSelector,
    {
      organizationId,
      projectId,
      environmentId
    }
  );

  if (data && !isRedirecting) {
    setIsRedirecting(true);
    dispatch(clearRedeploy());
    goToEnvironment(push, organizationId, projectId, environmentId);
  }

  return (
    <ModalWrapper
      id="environment-redeploy-modal"
      shouldCloseOnOverlayClick={true}
      isOpen={true}
      title={
        <strong>
          <FormattedMessage id="redeploy.modal.title" />
        </strong>
      }
      onRequestClose={() =>
        goToEnvironment(push, organizationId, projectId, environmentId)
      }
      closeModal={() =>
        goToEnvironment(push, organizationId, projectId, environmentId)
      }
      modalClass="modal-environment-action modal-environment-redeploy"
      announceTitle={intl.formatMessage({ id: "redeploy.modal.title" })}
    >
      <ContentPaneLayout className="modal-body">
        {error?.message && <Error>{error.message}</Error>}
        <p>
          <FormattedMessage id="redeploy.modal.text" />
        </p>
        <Note>
          <FormattedMessage id="redeploy.modal.note" />
        </Note>

        <Heading4 style={{ marginBottom: 16 }}>
          CLI{" "}
          <InfoDialog
            title="Learn more"
            text={intl.formatMessage({ id: "redeploy.modal.dialog" })}
            to={intl.formatMessage({
              id: "links.documentation.getting_started_CLI"
            })}
            linkText="Help"
          />
        </Heading4>
        <CopyableArea
          id="environment-redeploy-cmd"
          content={`${intl.formatMessage({
            id: "CLI_command_name"
          })} environment:redeploy`}
          singleLine={true}
        >
          {`${intl.formatMessage({
            id: "CLI_command_name"
          })} environment:redeploy`}
        </CopyableArea>
        {isEnvironmentLoading || isLoading ? (
          <Loading />
        ) : (
          <ButtonWrapper className="modal-buttons">
            <Button
              id="environment-redeploy-save-btn"
              aria-label={intl.formatMessage({ id: "redeploy.button" })}
              onClick={() => {
                dispatch(
                  redeploy({ organizationId, projectId, environmentId })
                );
              }}
            >
              {capitalize(intl.formatMessage({ id: "redeploy.button" }))}
            </Button>
            <Button
              id="environment-redeploy-cancel-btn"
              variant="secondary"
              aria-label={intl.formatMessage({ id: "cancel" })}
              onClick={() =>
                goToEnvironment(push, organizationId, projectId, environmentId)
              }
            >
              {capitalize(intl.formatMessage({ id: "cancel" }))}
            </Button>
          </ButtonWrapper>
        )}
      </ContentPaneLayout>
    </ModalWrapper>
  );
};

EnvironmentSyncPane.propTypes = {
  environment: PropTypes.object,
  isLoading: PropTypes.bool,
  push: PropTypes.func,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  environmentId: PropTypes.string.isRequired
};

export default EnvironmentSyncPane;
