import React, { useState } from "react";
import { useIntl } from "react-intl";

import getThirdParties from "../../../third_parties";

import useInput from "Hooks/useInput";

import ActionDropdown from "Components/ActionDropdown";
import CheckboxField from "Components/fields/CheckboxField";
import IntegrationCategory from "../../components/IntegrationCategory";
import Heading6 from "Components/styleguide/Heading6";
import IconFilter from "Icons/IconFilter";

import * as S from "./IntegrationsList.styles";

const IntegrationsList = () => {
  const intl = useIntl();
  const thirdParties = getThirdParties(intl);
  const categories = thirdParties.reduce((acc, cu) => {
    const cat = cu.category;
    if (!acc.includes(cat)) acc.push(cat);
    return acc;
  }, []);

  const { inputs, handleInputChange, setInputs } = useInput(
    categories.reduce((acc, cu) => {
      acc[cu] = true;
      return acc;
    }, {})
  );

  const [search, setSearch] = useState();

  const handleChange = e => {
    if (e) e.preventDefault();
    setSearch(e.target.value);
  };

  const getItems = cat => {
    return thirdParties.filter(elt => elt.category === cat);
  };

  const getCategories = () => {
    return categories.map(cat => {
      let items = getItems(cat);

      if (search) {
        items = items.filter(elt => {
          const formattedName = intl
            .formatMessage({
              id: `integration.type.${elt.type}`
            })
            .toLowerCase();
          return formattedName.includes(search.toLowerCase());
        });
      }
      if (items.length && inputs[cat])
        return (
          <IntegrationCategory key={`cat-${cat}`} name={cat} list={items} />
        );
    });
  };

  const selectAllCat = () => {
    for (const [key, value] of Object.entries(inputs)) {
      if (!value) setInputs(inputs => ({ ...inputs, [key]: true }));
    }
  };

  return (
    <S.Wrapper>
      <S.Header>
        <S.Search
          placeholder={intl.formatMessage({
            id: "integration.setup.categories.search.placeholder",
            defaultMessage: "Search integration"
          })}
          value={search}
          onChange={handleChange}
        />

        <ActionDropdown
          id="integration-filter"
          label={intl.formatMessage({
            id: "integration.setup.categories.filter",
            defaultMessage: "Filter"
          })}
          ariaLabel={intl.formatMessage({
            id: "integration.setup.categories.filter",
            defaultMessage: "Filter"
          })}
          icon={
            <span className="icon">
              <IconFilter />
            </span>
          }
          iconAfter={true}
          withArrow={false}
          withClose={true}
        >
          <Heading6>
            {intl.formatMessage({
              id: "integration.setup.categories.label",
              defaultMessage: "Filter integrations"
            })}
          </Heading6>
          <hr />

          <CheckboxField
            forId="all"
            label={intl.formatMessage({
              id: `integration.setup.categories.all`
            })}
            type="checkbox"
            value={Object.entries(inputs).every(elt => elt[1])}
            onChange={selectAllCat}
          />

          {categories.map(cat => (
            <CheckboxField
              key={`cat-${cat}`}
              forId={cat}
              label={intl.formatMessage({
                id: `integration.setup.categories.${cat}.title`
              })}
              type="checkbox"
              value={inputs[cat]}
              onChange={handleInputChange}
            />
          ))}
        </ActionDropdown>
      </S.Header>
      {getCategories()}
    </S.Wrapper>
  );
};

export default IntegrationsList;
