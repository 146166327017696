import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";
import { css, SECTION, getCSSVarString, ICON, BANNER } from "Libs/themes";

export const SYSTEM_STATUS = {
  WARNING: "warning",
  CRITICAL: "critical",
  MINOR: "minor",
  MAJOR: "major",
  SUCCESS: "success",
  NONE: "none",
  MAINTENANCE: "maintenance"
};

const getColorLevel = (level, theme) => {
  switch (level) {
    case SYSTEM_STATUS.NONE:
    case SYSTEM_STATUS.SUCCESS:
      return {
        bg:"var(--section-cosmic-latte-background-color,var(--section-cosmic-latte,var(--cosmic-latte)))",
        ico:"var(--icon-java-fill,var(--icon-java,var(--java)))"
      };
    case SYSTEM_STATUS.MAINTENANCE:
      return {
        bg:"var(--section-grey-background-color,var(--section-grey,var(--grey)))",
        ico:"var(--icon-gold-fill,var(--icon-gold,var(--gold)))"
      };

    case SYSTEM_STATUS.MINOR:
    case SYSTEM_STATUS.MAJOR:
    case SYSTEM_STATUS.WARNING:
      return {
        bg:"var(--section-tulip-pedals-background-color,var(--section-tulip-pedals,var(--tulip-pedals)))",
        ico:"var(--icon-gold-fill,var(--icon-gold,var(--gold)))"
      };
    case SYSTEM_STATUS.CRITICAL:
      return {
        bg:"var(--section-misty-rose-background-color,var(--section-misty-rose,var(--misty-rose)))",
        ico:"var(--icon-red-fill,var(--icon-red,var(--red)))"
      };
    default:
      return {
        bg:
          theme.name === "dark"
            ?"var(--section-ebony-background-color,var(--section-ebony,var(--ebony)))": "#FFF"
      };
  }
};

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  padding: 8px 16px;
  font-size: 13px;
  line-height: 24px;
  ${semiBoldAlias};
  color:var(--banner-ebony-color,var(--banner-ebony,var(--ebony)));
  background-color: ${props => getColorLevel(props.level, props.theme).bg};
  border-radius: 2px;

  & > span:first-child.icon-success-wrapper,
  .icon-warning-wrapper {
    margin-right: 14px;
    svg path {
      fill: ${props => getColorLevel(props.level, props.theme).ico};
    }
  }
`;

export const Link = styled.a`
  margin: 0 0 0 auto;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: inherit;
`;
