export default `
  --dark: #28283C;
  --skeleton-gradien: linear-gradient(90deg, rgba(48, 47, 69, 0) 0%, #302F45 48.96%, rgba(48, 47, 69, 0) 100%);

  --button-box-shadow-box-shadow: var(--box-shadow);
  --button-dark-shade-box-shadow-color: var(--dark-shade);
  --button-dark-tint-background-color: var(--dark-tint);
  --button-dark-tint-color: var(--dark-tint);
  --button-deep-blue-border-color: var(--deep-blue);
  --button-deep-blue-box-shadow-color: var(--deep-blue);
  --button-ebony-light-background-color: var(--ebony-light);
  --button-ebony-light-fill: var(--ebony-light);
  --button-granite-background-color: var(--granite);
  --button-granite-box-shadow-color: var(--granite);
  --button-granite-color: var(--granite);
  --button-grey-background-color: var(--dark-tint);
  --button-night-color: var(--snow);
  --button-periwinkle-grey-background-color: var(--periwinkle-grey);
  --button-periwinkle-grey-border-color: transparent;
  --button-red-color: var(--red);
  --button-skye-background-color: var(--skye);
  --button-skye-border-color: var(--skye);
  --button-skye-box-shadow-color: var(--skye);
  --button-skye-color: var(--skye);
  --button-skye-dark-background-color: var(--skye-dark);
  --button-skye-dark-border-color: var(--skye-dark);
  --button-skye-dark-box-shadow-color: var(--skye-dark);
  --button-skye-dark-color: var(--skye-dark);
  --button-skye-dark-fill: var(--skye-dark);
  --button-skye-dark-outline-color: var(--skye-dark);
  --button-skye-fill: var(--snow);
  --button-skye-light-background-color: var(--light-blue);
  --button-slate-background-color: var(--slate);
  --button-slate-color: var(--periwinkle-grey);
  --button-slate-fill: var(--slate);
  --button-snow-background-color: var(--dark-tint);
  --button-snow-border-color: var(--snow);
  --button-snow-color: var(--snow);
  --button-snow-fill: var(--snow);
  --banner-ebony-color: var(--ebony);
  --header-ebony-background-color: var(--ebony);
  --header-ebony-color: var(--snow);
  --header-ebony-light-background-color: var(--ebony-light);
  --header-ebony-light-border-bottom-color: var(--ebony-light);
  --header-granite-color: var(--granite);
  --header-night-color: var(--snow);
  --header-periwinkle-grey-border-bottom-color: var(--ebony-light);
  --header-periwinkle-grey-color: var(--periwinkle-grey);
  --header-slate-color: var(--periwinkle-grey);
  --header-snow-color: var(--snow);
  --icon-ebony-fill: var(--ebony);
  --icon-ebony-light-color: var(--snow);
  --icon-ebony-light-fill: var(--periwinkle-grey);
  --icon-ebony-light-stroke: var(--periwinkle-grey);
  --icon-fire-fill: var(--fire);
  --icon-gold-fill: var(--gold);
  --icon-granite-color: var(--granite);
  --icon-granite-fill: var(--periwinkle-grey);
  --icon-grey-stroke: var(--ebony-light);
  --icon-java-background-color: var(--ebony-light);
  --icon-java-border-color: var(--java);
  --icon-java-fill: var(--java);
  --icon-night-color: var(--snow);
  --icon-periwinkle-grey-color: var(--periwinkle-grey);
  --icon-periwinkle-grey-fill: var(--periwinkle-grey);
  --icon-red-background-color: var(--red);
  --icon-red-fill: var(--red);
  --icon-skye-background-color: var(--dark-tint);
  --icon-skye-color: var(--skye);
  --icon-skye-dark-fill: var(--skye);
  --icon-skye-fill: var(--skye);
  --icon-slate-color: var(--periwinkle-grey);
  --icon-slate-fill: var(--periwinkle-grey);
  --icon-slate-stroke: var(--periwinkle-grey);
  --icon-snow-fill: var(--snow);
  --icon-stone-background-color: var(--stone);
  --icon-stone-fill: var(--stone);
  --input-box-shadow: var(--box-shadow);
  --input-dark-shade-border-color: var(--dark-shade);
  --input-ebony-background-color: var(--ebony);
  --input-ebony-light-color: var(--snow);
  --input-ebony-shadow-box-shadow-color: var(--black);
  --input-granite-border-color: var(--slate);
  --input-granite-color: var(--granite);
  --input-ice-background-color: var(--dark-shade);
  --input-ice-border-color: var(--dark-shade);
  --input-night-color: var(--snow);
  --input-periwinkle-grey-background-color: var(--periwinkle-grey);
  --input-periwinkle-grey-border-color: var(--periwinkle-grey);
  --input-red-border-color: var(--red);
  --input-skye-background-color: var(--skye);
  --input-skye-border-color: var(--skye);
  --input-skye-color: var(--skye);
  --input-skye-dark-border-color: var(--skye-dark);
  --input-skye-dark-box-shadow-color: var(--skye-dark);
  --input-skye-dark-box-shadow: var(--skye-dark);
  --input-skye-light-background-color: var(--ebony-light);
  --input-skye-light-border-color: var(--skye-light);
  --input-slate-color: var(--slate);
  --input-snow-color: var(--snow);
  --input-transparent-border-color: var(--transparent);
  --link-dark-shade-box-shadow-color: var(--dark-shade);
  --link-dark-tint-background-color: var(--dark-tint);
  --link-dark-tint-color: var(--dark-tint);
  --link-ebony-background-color: var(--ebony);
  --link-ebony-color: var(--snow);
  --link-ebony-light-border-color: var(--ebony-light);
  --link-ebony-light-color: var(--granite);
  --link-granite-color: var(--granite);
  --link-grey-background-color: var(--grey);
  --link-night-background-color: var(--snow);
  --link-night-color: var(--snow);
  --link-periwinkle-grey-border-color: var(--slate);
  --link-periwinkle-grey-color: var(--periwinkle-grey);
  --link-skye-background-color: var(--skye);
  --link-skye-border-color: var(--skye);
  --link-skye-color: var(--skye);
  --link-skye-dark-background-color: var(--skye-dark);
  --link-skye-dark-border-color: var(--skye-dark);
  --link-skye-dark-box-shadow-color: var(--skye-dark);
  --link-skye-dark-color: var(--skye-dark);
  --link-skye-fill: var(--skye);
  --link-skye-light-background-color: var(--ebony-light);
  --link-skye-light-color: var(--skye-light);
  --link-slate-border-color: var(--slate);
  --link-slate-color: var(--periwinkle-grey);
  --link-snow-background-color: var(--dark-tint);
  --link-snow-color: var(--snow);
  --menu-box-shadow-box-shadow: var(--box-shadow);
  --menu-box-shadow: 0 6px 24px;
  --menu-dark-tint-background-color: var(--dark-tint);
  --menu-dark-tint-color: var(--snow);
  --menu-ebony-light-box-shadow-color: var(--night);
  --menu-ebony-light-color: var(--snow);
  --menu-ebony-shadow-box-shadow-color: var(--black);
  --menu-granite-border-color: var(--granite);
  --menu-ice-background-color: var(--ebony-light);
  --menu-item-ebony-light-background-color: var(--ebony-light);
  --menu-item-skye-background-color: var(--ebony-light);
  --menu-item-skye-color: var(--skye);
  --menu-item-skye-light-background-color: var(--ebony-light);
  --menu-item-skye-light-color: var(--skye-light);
  --menu-item-slate-color: var(--snow);
  --menu-periwinkle-grey-background-color: var(--periwinkle-grey);
  --menu-periwinkle-grey-box-shadow-color: var(--dark-tint);
  --menu-skye-color: var(--skye);
  --menu-snow-background-color: var(--ebony);
  --modal-dark-shade-background-color: var(--dark-shade);
  --modal-ebony-background-color: var(--night);
  --modal-ebony-light-background-color: var(--night);
  --modal-ebony-light-color: var(--ebony-light);
  --modal-granite-color: var(--granite);
  --modal-light-blue-background-color: var(--light-blue);
  --modal-night-color: var(--snow);
  --modal-periwinkle-grey-color: var(--periwinkle-grey);
  --modal-skye-background-color: var(--skye);
  --modal-skye-color: var(--skye);
  --modal-snow-background-color: var(--ebony);
  --modal-snow-color: var(--snow);
  --navbar-dark-shade-background-color: var(--ebony);
  --navbar-snow-color: var(--ebony-light);
  --section-box-shadow-box-shadow: var(--box-shadow);
  --section-box-shadow: var(--box-shadow);
  --section-cosmic-latte-background-color: var(--cosmic-latte);
  --section-dark-background-color: var(--ebony);
  --section-dark-color: var(--dark);
  --section-dark-shade-background-color: var(--dark-shade);
  --section-dark-tint-background-color: var(--dark-tint);
  --section-dark-tint-color: var(--periwinkle-grey);
  --section-dark-tint-fill: var(--dark-tint);
  --section-ebony-background-color: var(--night);
  --section-ebony-border-color: var(--ebony);
  --section-ebony-color: var(--snow);
  --section-ebony-light-background-color: var(--ebony-light);
  --section-ebony-light-border-bottom-color: var(--ebony-light);
  --section-ebony-light-border-color: var(--ebony-light);
  --section-ebony-light-box-shadow-color: var(--black);
  --section-ebony-light-color: var(--snow);
  --section-ebony-light: var(--ebony-light);
  --section-ebony-shadow-box-shadow-color: var(--black);
  --section-fire-color: var(--fire);
  --section-gold-background-color: var(--gold);
  --section-gold-border-color: var(--gold);
  --section-granite-background-color: var(--granite);
  --section-granite-border-color: var(--granite);
  --section-granite-box-shadow-color: var(--black);
  --section-granite-color: var(--periwinkle-grey);
  --section-granite-shadow-box-shadow-color: var(--night);
  --section-grey-background-color: var(--dark-shade);
  --section-grey-border-color: var(--dark-shade);
  --section-ice-background-color: rgba(26, 26, 43, 0.5);
  --section-ice-border-color: var(--ice);
  --section-java-background-color: var(--java);
  --section-java-border-color: var(--java);
  --section-java-color: var(--java);
  --section-lemon-chiffon-background-color: var(--lemon-chiffon);
  --section-light-pink-background-color: var(--light-pink);
  --section-menu-box-shadow-box-shadow: var(--menu-box-shadow);
  --section-misty-rose-background-color: var(--misty-rose);
  --section-night-background-color: var(--night);
  --section-night-border-color: var(--night);
  --section-night-color: var(--snow);
  --section-old-gold-color: var(--old-gold);
  --section-periwinkle-grey-background-color: var(--dark-tint);
  --section-periwinkle-grey-border-bottom-color: var(--ebony-light);
  --section-periwinkle-grey-border-color: var(--ebony-light);
  --section-periwinkle-grey-box-shadow-color: var(--periwinkle-grey);
  --section-periwinkle-grey-color: var(--periwinkle-grey);
  --section-periwinkle-grey-line: var(--periwinkle-grey);
  --section-red-background-color: var(--red);
  --section-red-border-color: var(--red);
  --section-red-color: var(--red);
  --section-skeleton-gradien-background-color: var(--skeleton-gradien);
  --section-skye-background-color: var(--skye-light);
  --section-skye-border-color: var(--skye);
  --section-skye-box-shadow-color: var(--skye);
  --section-skye-color: var(--periwinkle-grey);
  --section-skye-dark-background-color: var(--skye-dark);
  --section-skye-dark-border-color: var(--skye-dark);
  --section-skye-dark-box-shadow-color: var(--skye-dark);
  --section-skye-dark-color: var(--skye-dark);
  --section-skye-fill: var(--skye);
  --section-skye-light-background-color: var(--ebony-light);
  --section-skye-light-border-color: var(--ebony-light);
  --section-slate-background-color: var(--slate);
  --section-slate-color: var(--periwinkle-grey);
  --section-slate-fill: var(--slate);
  --section-snow-background-color: var(--dark);
  --section-snow-color: var(--snow);
  --section-stone-background-color: var(--ebony-light);
  --section-stone-border-color: var(--ebony-light);
  --section-transparent-background-color: var(--transparent);
  --section-transparent-border-color: var(--transparent);
  --section-tulip-pedals-background-color: var(--tulip-pedals);
  --section-wine-color: var(--wine);
  --tab-ebony-background-color: var(--ebony);
  --tab-granite-color: var(--granite);
  --tab-skye-background-color: var(--skye);
  --tab-skye-color: var(--skye);
  --tab-skye-fill: var(--skye);
  --tab-snow-background-color: var(--dark);
  --tab-snow-color: var(--snow);
  --tooltip-black-box-shadow-color: var(--black);
  --tooltip-box-shadow: var(--box-shadow);
  --tooltip-ebony-background-color: var(--ebony);
  --tooltip-ebony-border-color: var(--ebony);
  --tooltip-skye-border-color: var(--skye);
  --tooltip-skye-box-shadow-color: var(--skye);
  --tooltip-snow-color: var(--snow);
  --icon-grey-background-color: var(--grey);
  --modal-dark-shade-overlay-background-color: var(--dark-shade-overlay);
  --tab-ebony-light-color: var(--ebony-light);
  --tab-snow-border-color: var(--snow);
  --divider-periwinkle-grey-background-color: var(--dark-tint);
  --divider-stone-background-color: var(--ebony-light);
  --modal-gold-color: var(--gold);
  --modal-light-blue-hover-background-color: var(--light-blue-hover);
  --modal-slate-color: var(--slate);
  --tooltip-ebony-shadow-box-shadow-color: var(--black);


  --settings-navbar-background-color: var(--ebony);
  --root-environment-icon-fill: var(--java);
  --divider-section-color: var(--ebony-light);
  --has-children-environment-icon-fill: var(--skye);
  --setting-line-hover-section-background-color: var(--ebony);
  --side-bar-hover-tab-background-color: var(--ebony);
  --main-url-section-background-color: var(--ebony);
  --activity-list-item-hover-section-background-color: var(--ebony);
  --setup-card-section-background-color: var(--dark-tint);
  --screenshot-text-section-color: var(--slate);
  --timerange-button-color: var(--snow);
  --timerange-section-background-color: var(--ebony-light);
  --project-preview-section-background-color: var(--slate);
`;
