import React, { useEffect } from "react";
import PropTypes from "prop-types";
import UserAvatar from "react-user-avatar";
import { useDispatch, useSelector } from "react-redux";

import { loadUser } from "Reducers/user";

import { Layout, Name, avatarColors } from "./styles";

const UserDetails = ({ userId }) => {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user.getIn(["data", userId || ""]));

  useEffect(() => {
    if (!user && userId) {
      dispatch(loadUser(userId));
    }
  }, [userId]);

  return user ? (
    <Layout>
      <UserAvatar
        size="24"
        colors={avatarColors}
        name={user.display_name || "User"}
        src={user.picture?.includes("user-default.png") ? null : user.picture}
      />
      <Name id="description-username">{user.display_name}</Name>
    </Layout>
  ) : null;
};

UserDetails.propTypes = {
  userId: PropTypes.string
};

export default UserDetails;
