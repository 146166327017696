import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";
import CustomScroll from "react-custom-scroll";
import { List } from "immutable";

import { saveConfig } from "Reducers/project/setup";
import Loading from "Components/Loading";
import withReducers from "Hocs/withReducers";

import PageMeta from "Components/PageMeta";
import Heading2 from "Components/styleguide/Heading2";
import Button from "Components/Button";
import Dropdown from "Components/Dropdown";
import SearchInput from "Components/SearchInput";
import useQueryParams from "Hooks/useQueryStrings";

import TemplateItem from "./TemplateItem";
import TemplateSelectModal from "./TemplateSelectModal";

import * as S from "./Template.styles";

import getIsLoadingState from "Reducers/utils";

const TemplateStep = ({ goToStep, organizationId: organizationIdParam }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const {
    query: querySearch,
    lang: queryLanguage,
    type: queryType
  } = useQueryParams(["lang", "type", "query"]);

  const [templates, setTemplates] = useState();
  const [language, setLanguage] = useState(null);
  const [modal, setModal] = useState({ isOpen: false, template: null });
  const [search, setSearch] = useState(decodeURIComponent(querySearch || ""));
  const [type, setType] = useState(null);

  const organizationId = config?.organization?.value || organizationIdParam;
  const config = useSelector(({ setup }) => setup?.get("config"))?.toJS();
  const isLoadingCatalog = useSelector(({ organizationSetup }) =>
    getIsLoadingState(organizationSetup, organizationId)
  );

  const updateUrlQueryString = () => {
    const url = new URL(window.location.href);
    language
      ? url.searchParams.set("lang", encodeURIComponent(language))
      : url.searchParams.delete("lang");
    type
      ? url.searchParams.set("type", encodeURIComponent(type))
      : url.searchParams.delete("type");
    search
      ? url.searchParams.set("query", encodeURIComponent(search))
      : url.searchParams.delete("query");
    window.history.pushState({}, "", url);
  };

  useEffect(() => {
    updateUrlQueryString();
  }, [type, search, language]);

  const catalog = useSelector(({ setup, organizationSetup }) => {
    if (
      process.env.ENABLE_ORGANIZATION &&
      organizationId &&
      organizationId !== "projects"
    ) {
      return organizationSetup?.getIn(
        [organizationId, "catalog", "data"],
        List()
      );
    } else {
      return setup?.getIn(["catalog", "data"], List());
    }
  });

  useEffect(() => {
    if (catalog.size) setTemplates(catalog.toJS());
  }, [catalog.size]);

  const { languageOptions, typeOptions } = useMemo(() => {
    let [languageOptions, typeOptions] = [[], []];
    if (templates) {
      const options = templates?.reduce(
        (accum, tpl) => {
          const tags = tpl?.info?.tags;
          if (tags) {
            accum?.language.add(tags[0]);
            tags.forEach(
              tag => !accum?.language.has(tag) && accum?.type.add(tag)
            );
          }
          return accum;
        },
        { language: new Set(), type: new Set() }
      );

      const sortFunc = (a, b) => a.toLowerCase().localeCompare(b.toLowerCase());

      languageOptions = Array.from(options?.language)
        .sort(sortFunc)
        .map(lang => ({ value: lang, label: lang }));
      languageOptions.unshift({ value: "all", label: "All languages" });

      typeOptions = Array.from(options?.type)
        .sort(sortFunc)
        .map(lang => ({ value: lang, label: lang }));
      typeOptions.unshift({ value: "all", label: "All types" });
    }
    return { languageOptions, typeOptions };
  }, [templates]);

  useEffect(() => {
    if (typeOptions || languageOptions) {
      const decodeQueryLang = decodeURIComponent(queryLanguage || "");
      const decodeQueryType = decodeURIComponent(queryType || "");

      if (
        typeOptions.some(
          t => t.value.toLowerCase() === decodeQueryType.toLowerCase()
        )
      )
        setType(decodeQueryType);
      if (
        languageOptions.some(
          t => t.value.toLowerCase() === decodeQueryLang.toLowerCase()
        )
      )
        setLanguage(decodeQueryLang);
    }
  }, [queryLanguage, queryType, typeOptions, languageOptions]);

  const isInList = tpl => {
    const tags = tpl.info?.tags || [];
    if (type && !tags.includes(type)) return false;
    if (language && !tags.includes(language)) return false;
    if (search && !tpl.info?.name.toLowerCase().includes(search.toLowerCase()))
      return false;
    return true;
  };

  const filteredList = templates
    ?.sort((a, b) =>
      a.info.name.toLowerCase().localeCompare(b.info.name.toLowerCase())
    )
    .filter(isInList);

  const handleSelectTemplate = tpl => {
    dispatch(saveConfig({ template: tpl.template }));
    goToStep("info");
  };

  const handleGoBack = e => {
    e?.preventDefault();
    goToStep("");
  };

  return (
    <S.Wrapper
      id="template-list"
      role="tabpanel"
      aria-labelledby="tab-template"
    >
      <PageMeta title={intl.formatMessage({ id: `setup.template.title` })} />
      <S.Header>
        <Heading2>
          {intl.formatMessage({ id: `setup.template.title` })}
        </Heading2>
      </S.Header>

      <LiveMessage
        message={intl.formatMessage({ id: "tab_template" })}
        aria-live="polite"
      />
      {language && (
        <LiveMessage
          message={`filtering list by ${language}`}
          aria-live="polite"
        />
      )}
      {type && (
        <LiveMessage message={`filtering list by ${type}`} aria-live="polite" />
      )}
      {search && (
        <LiveMessage
          message={`filtering list by ${search}`}
          aria-live="polite"
        />
      )}

      <S.FiltersWrapper>
        <SearchInput
          id="template-search-input"
          placeholder={intl.formatMessage({ id: "search_templates" })}
          iconSize={18}
          onChange={event => setSearch(event.target.value)}
          value={search}
          className="float"
        />
        <div>
          <S.SelectorWrapper className="filter-tags filter-type">
            <Dropdown
              options={typeOptions}
              name="select_type"
              onChange={elt => {
                setType(typeof elt.value !== "string" ? null : elt.value);
              }}
              searchable={false}
              value={type ? { value: type, label: type } : undefined}
              defaultValue={{ value: "all", label: "All types" }}
              clearable={false}
              className="outlined"
            />
          </S.SelectorWrapper>
          <S.SelectorWrapper className="filter-tags filter-language">
            <Dropdown
              options={languageOptions}
              name="select_language"
              onChange={elt => {
                setLanguage(typeof elt.value !== "string" ? null : elt.value);
              }}
              value={
                language ? { value: language, label: language } : undefined
              }
              searchable={false}
              defaultValue={{ value: "all", label: "All languages" }}
              clearable={false}
              className="outlined"
            />
          </S.SelectorWrapper>
        </div>
      </S.FiltersWrapper>

      <CustomScroll addScrolledClass={true} heightRelativeToParent="100%">
        <S.ResultsWrapper className="templates-wrapper">
          {filteredList?.map((tpl, i) => {
            return (
              <TemplateItem
                key={`template-${i}`}
                template={tpl}
                onClick={() => handleSelectTemplate(tpl)}
                openModal={() => {
                  setModal({ isOpen: true, template: tpl });
                }}
                modalOpen={modal.isOpen}
              />
            );
          })}
        </S.ResultsWrapper>
        {isLoadingCatalog && (
          <S.LoadingWrapper>
            <Loading />
          </S.LoadingWrapper>
        )}
      </CustomScroll>

      <S.ActionButtons>
        <Button className="secondary" type="button" onClick={handleGoBack}>
          {intl.formatMessage({ id: "back" })}
        </Button>
      </S.ActionButtons>

      {modal.isOpen && (
        <TemplateSelectModal
          isOpen={modal.isOpen}
          template={modal.template}
          onSave={() => handleSelectTemplate(modal.template)}
          closeModal={() => setModal({ isOpen: false, template: null })}
        />
      )}
    </S.Wrapper>
  );
};

TemplateStep.propTypes = {
  goToStep: PropTypes.func,
  organizationId: PropTypes.string
};

export default withReducers({
  setup: () => import("Reducers/project/setup"),
  organizationSetup: () => import("Reducers/organization/setup")
})(TemplateStep);
