export default `
    --skye: #1664ff;
    --skye-dark: var(--deep-blue);

    --button-box-shadow-box-shadow: var(--box-shadow);
    --button-dark-shade-box-shadow-color: none;
    --button-dark-tint-background-color: var(--dark-tint);
    --button-dark-tint-color: var(--dark-tint);
    --button-deep-blue-border-color: var(--deep-blue);
    --button-deep-blue-box-shadow-color: var(--deep-blue);
    --button-ebony-light-background-color: var(--ebony-light);
    --button-ebony-light-fill: var(--ebony-light);
    --button-granite-background-color: var(--granite);
    --button-granite-box-shadow-color: var(--granite);
    --button-granite-color: var(--granite);
    --button-grey-background-color: var(--grey);
    --button-night-color: var(--ebony-light);
    --button-periwinkle-grey-background-color: var(--periwinkle-grey);
    --button-periwinkle-grey-border-color: var(--periwinkle-grey);
    --button-red-color: var(--red);
    --button-skye-background-color: var(--skye);
    --button-skye-border-color: var(--skye);
    --button-skye-box-shadow-color: var(--skye);
    --button-skye-color: var(--skye);
    --button-skye-dark-background-color: var(--skye-dark);
    --button-skye-dark-border-color: var(--skye-dark);
    --button-skye-dark-box-shadow-color: var(--skye-dark);
    --button-skye-dark-color: var(--skye-dark);
    --button-skye-dark-fill: var(--skye-dark);
    --button-skye-dark-outline-color: var(--skye-dark);
    --button-skye-fill: var(--skye);
    --button-skye-light-background-color: var(--skye-light);
    --button-slate-background-color: var(--slate);
    --button-slate-color: var(--slate);
    --button-slate-fill: var(--slate);
    --button-snow-background-color: var(--snow);
    --button-snow-border-color: var(--snow);
    --button-snow-color: var(--snow);
    --button-snow-fill: var(--snow);
    --banner-ebony-color: var(--ebony);
    --header-ebony-background-color: var(--ebony);
    --header-ebony-color: var(--ebony);
    --header-ebony-light-background-color: var(--ebony-light);
    --header-ebony-light-border-bottom-color: var(--ebony-light);
    --header-granite-color: var(--granite);
    --header-night-color: var(--night);
    --header-periwinkle-grey-border-bottom-color: var(--periwinkle-grey);
    --header-periwinkle-grey-color: var(--periwinkle-grey);
    --header-slate-color: var(--slate);
    --header-snow-color: var(--snow);
    --icon-ebony-fill: var(--ebony);
    --icon-ebony-light-color: var(--slate);
    --icon-ebony-light-fill: var(--ebony-light);
    --icon-ebony-light-stroke: var(--ebony-light);
    --icon-fire-fill: var(--fire);
    --icon-gold-fill: var(--gold);
    --icon-granite-color: var(--slate);
    --icon-granite-fill: var(--ebony-light);
    --icon-grey-stroke: var(--grey);
    --icon-java-background-color: var(--java);
    --icon-java-fill: var(--java);
    --icon-night-color: var(--night);
    --icon-periwinkle-grey-color: var(--periwinkle-grey);
    --icon-periwinkle-grey-fill: var(--periwinkle-grey);
    --icon-red-background-color: var(--red);
    --icon-red-fill: var(--red);
    --icon-skye-background-color: var(--skye);
    --icon-skye-color: var(--skye);
    --icon-skye-dark-fill: var(--skye-dark);
    --icon-skye-fill: var(--snow);
    --icon-slate-color: var(--slate);
    --icon-slate-fill: var(--ebony-light);
    --icon-slate-stroke: var(--slate);
    --icon-snow-fill: var(--snow);
    --icon-stone-background-color: var(--stone);
    --icon-stone-fill: var(--stone);
    --input-box-shadow: var(--box-shadow);
    --input-dark-shade-border-color: var(--dark-shade);
    --input-ebony-background-color: var(--black);
    --input-ebony-light-color: var(--ebony-light);
    --input-ebony-shadow-box-shadow-color: var(--ebony-shadow);
    --input-granite-border-color: var(--granite);
    --input-granite-color: var(--granite);
    --input-ice-background-color: var(--ice);
    --input-ice-border-color: var(--granite);
    --input-night-color: var(--night);
    --input-periwinkle-grey-background-color: var(--periwinkle-grey);
    --input-periwinkle-grey-border-color: var(--periwinkle-grey);
    --input-red-border-color: var(--red);
    --input-skye-background-color: var(--skye);
    --input-skye-border-color: var(--skye);
    --input-skye-color: var(--skye);
    --input-skye-dark-border-color: var(--skye-dark);
    --input-skye-dark-box-shadow-color: var(--skye-dark);
    --input-skye-dark-box-shadow: var(--skye-dark);
    --input-skye-light-border-color: var(--skye-light);
    --input-slate-color: var(--slate);
    --input-snow-color: var(--snow);
    --input-transparent-border-color: var(--granite);
    --link-dark-shade-box-shadow-color: none;
    --link-dark-tint-background-color: var(--dark-tint);
    --link-dark-tint-color: var(--dark-tint);
    --link-ebony-background-color: var(--ebony);
    --link-ebony-color: var(--skye-dark);
    --link-ebony-light-border-color: var(--ebony-light);
    --link-ebony-light-color: var(--ebony-light);
    --link-granite-color: var(--granite);
    --link-grey-background-color: var(--grey);
    --link-night-background-color: var(--night);
    --link-night-color: var(--night);
    --link-periwinkle-grey-border-color: var(--periwinkle-grey);
    --link-periwinkle-grey-color: var(--periwinkle-grey);
    --link-skye-background-color: var(--skye);
    --link-skye-border-color: var(--skye);
    --link-skye-color: var(--skye);
    --link-skye-dark-background-color: var(--skye-dark);
    --link-skye-dark-border-color: var(--skye-dark);
    --link-skye-dark-box-shadow-color: var(--deep-blue);
    --link-skye-dark-color: var(--skye-dark);
    --link-skye-fill: var(--skye-dark);
    --link-skye-light-background-color: var(--skye-light);
    --link-skye-light-color: var(--skye-light);
    --link-slate-border-color: var(--slate);
    --link-slate-color: var(--slate);
    --link-snow-background-color: var(--deep-blue);
    --link-snow-color: var(--snow);
    --menu-box-shadow-box-shadow: var(--box-shadow);
    --menu-box-shadow: 0 6px 24px;
    --menu-dark-tint-background-color: var(--dark-tint);
    --menu-dark-tint-color: var(--dark-tint);
    --menu-ebony-light-box-shadow-color: var(--ebony-light);
    --menu-ebony-light-color: var(--ebony-light);
    --menu-ebony-shadow-box-shadow-color: var(--ebony-shadow);
    --menu-granite-border-color: var(--granite);
    --menu-ice-background-color: var(--ice);
    --menu-item-ebony-light-background-color: var(--ebony-light);
    --menu-item-skye-background-color: var(--skye);
    --menu-item-skye-color: var(--skye);
    --menu-item-skye-light-background-color: var(--skye-light);
    --menu-item-skye-light-color: var(--skye-light);
    --menu-item-slate-color: var(--slate);
    --menu-periwinkle-grey-background-color: var(--periwinkle-grey);
    --menu-periwinkle-grey-box-shadow-color: var(--periwinkle-grey);
    --menu-skye-color: var(--skye);
    --menu-snow-background-color: var(--snow);
    --modal-dark-shade-background-color: var(--dark-shade);
    --modal-ebony-background-color: var(--ebony);
    --modal-ebony-light-background-color: var(--ebony-light);
    --modal-ebony-light-color: var(--ebony-light);
    --modal-granite-color: var(--granite);
    --modal-light-blue-background-color: var(--light-blue);
    --modal-night-color: var(--night);
    --modal-periwinkle-grey-color: var(--periwinkle-grey);
    --modal-skye-background-color: var(--skye);
    --modal-skye-color: var(--skye);
    --modal-snow-background-color: var(--snow);
    --modal-snow-color: var(--snow);
    --navbar-dark-shade-background-color: var(--dark-shade);
    --navbar-snow-color: var(--snow);
    --section-box-shadow-box-shadow: var(--box-shadow);
    --section-box-shadow: var(--box-shadow);
    --section-cosmic-latte-background-color: var(--cosmic-latte);
    --section-dark-background-color: var(--dark);
    --section-dark-color: var(--dark);
    --section-dark-shade-background-color: var(--dark-shade);
    --section-dark-tint-background-color: var(--dark-tint);
    --section-dark-tint-color: var(--dark-tint);
    --section-dark-tint-fill: var(--dark-tint);
    --section-ebony-background-color: var(--ebony);
    --section-ebony-border-color: var(--ebony);
    --section-ebony-color: var(--ebony);
    --section-ebony-light-background-color: var(--ebony-light);
    --section-ebony-light-border-bottom-color: var(--ebony-light);
    --section-ebony-light-border-color: var(--ebony-light);
    --section-ebony-light-box-shadow-color: var(--ebony-light);
    --section-ebony-light-color: var(--ebony-light);
    --section-ebony-light: var(--ebony-light);
    --section-ebony-shadow-box-shadow-color: var(--ebony-shadow);
    --section-fire-color: var(--fire);
    --section-gold-background-color: var(--gold);
    --section-gold-border-color: var(--gold);
    --section-granite-background-color: var(--granite);
    --section-granite-border-color: var(--granite);
    --section-granite-box-shadow-color: var(--granite);
    --section-granite-color: var(--ebony-light);
    --section-granite-shadow-box-shadow-color: var(--granite-shadow);
    --section-grey-background-color: var(--grey);
    --section-grey-border-color: var(--grey);
    --section-ice-background-color: var(--ice);
    --section-ice-border-color: var(--ice);
    --section-java-background-color: var(--java);
    --section-java-border-color: var(--java);
    --section-java-color: var(--java);
    --section-lemon-chiffon-background-color: var(--lemon-chiffon);
    --section-light-blue-background-color: var(--light-blue);
    --section-light-pink-background-color: var(--light-pink);
    --section-menu-box-shadow-box-shadow: var(--menu-box-shadow);
    --section-misty-rose-background-color: var(--misty-rose);
    --section-night-background-color: var(--night);
    --section-night-border-color: var(--night);
    --section-night-color: var(--night);
    --section-old-gold-color: var(--old-gold);
    --section-periwinkle-grey-background-color: var(--periwinkle-grey);
    --section-periwinkle-grey-border-bottom-color: var(--periwinkle-grey);
    --section-periwinkle-grey-border-color: var(--periwinkle-grey);
    --section-periwinkle-grey-box-shadow-color: var(--periwinkle-grey);
    --section-periwinkle-grey-color: var(--periwinkle-grey);
    --section-periwinkle-grey-line: var(--periwinkle-grey);
    --section-red-background-color: var(--red);
    --section-red-border-color: var(--red);
    --section-red-color: var(--red);
    --section-skeleton-gradien-background-color: var(--skeleton-gradien);
    --section-skye-background-color: var(--skye);
    --section-skye-border-color: var(--skye);
    --section-skye-box-shadow-color: var(--skye);
    --section-skye-color: var(--skye);
    --section-skye-dark-background-color: var(--skye-dark);
    --section-skye-dark-border-color: var(--skye-dark);
    --section-skye-dark-box-shadow-color: var(--skye-dark);
    --section-skye-dark-color: var(--skye-dark);
    --section-skye-fill: var(--skye);
    --section-skye-light-background-color: var(--skye-light);
    --section-skye-light-border-color: var(--skye-light);
    --section-slate-background-color: var(--slate);
    --section-slate-color: var(--slate);
    --section-slate-fill: var(--slate);
    --section-snow-background-color: var(--snow);
    --section-snow-color: var(--snow);
    --section-snow: var(--snow);
    --section-stone-background-color: var(--ebony-light);
    --section-stone-border-color: var(--stone);
    --section-transparent-background-color: var(--transparent);
    --section-transparent-border-color: var(--transparent);
    --section-tulip-pedals-background-color: var(--tulip-pedals);
    --section-wine-color: var(--wine);
    --skeleton-gradien: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 48.96%, rgba(255, 255, 255, 0) 100%);
    --tab-ebony-background-color: var(--ebony);
    --tab-granite-color: var(--granite);
    --tab-skye-background-color: var(--skye);
    --tab-skye-color: var(--skye);
    --tab-skye-fill: var(--skye);
    --tab-snow-background-color: var(--snow);
    --tab-snow-color: var(--snow);
    --tooltip-box-shadow: var(--box-shadow);
    --tooltip-ebony-background-color: var(--ebony);
    --tooltip-ebony-border-color: var(--ebony);
    --tooltip-ebony-shadow-box-shadow-color: var(--ebony-shadow);
    --tooltip-skye-border-color: var(--skye);
    --tooltip-skye-box-shadow-color: var(--skye);
    --tooltip-snow-color: var(--snow);
    --input-skye-light-background-color: var(--skye-light);
    --icon-grey-background-color: var(--grey);
    --modal-dark-shade-overlay-background-color: var(--dark-shade-overlay);
    --tooltip-black-box-shadow-color: var(--black);
    --tab-ebony-light-color: var(--ebony-light);
    --tab-snow-border-color: var(--snow);
    --modal-gold-color: var(--gold);
    --modal-light-blue-hover-background-color: var(--light-blue-hover);
    --modal-slate-color: var(--slate);
    --divider-periwinkle-grey-background-color: var(--periwinkle-grey);
    --divider-stone-background-color: var(--ebony-light);
    --screenshot-text-section-color: var(--granite);
    --no-children-environment-icon-fill: var(--skye);
`;
