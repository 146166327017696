import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl, FormattedMessage } from "react-intl";
import { Map } from "immutable";

import SettingLine from "Components/SettingLine";
import SshKeyForm from "Components/SshKeyForm";
import Heading2 from "Components/styleguide/Heading2";
import EmptyText from "Components/EmptyText";
import ButtonAdd from "Components/ButtonAdd";
import Loading from "Components/Loading";
import SshKeyView from "./SshKeyView";
import PageDescription from "Components/PageDescription";
import InfoDialog from "Components/InfoDialog";
import InfoIcon from "Icons/InfoIcon";
import NotificationModal from "Components/NotificationModal";
import Error from "Components/LargeInlineError";
import { MediaQuery } from "Hooks/useMediaQuery";

const SshKeyWrapper = styled.div`
  p {
    margin-bottom: 30px;
  }
`;

const SshKeyListHeading2 = styled(Heading2)`
  margin-bottom: 0;
  font-size: 18px;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

const SshKeyListInfoDialog = styled(InfoDialog)`
  &.info-dialog {
    margin-right: 16px;
  }
`;

const SshKeyListPageDescription = styled(PageDescription)`
  max-width: none;
  display: flex;
`;

const InfoLayout = styled.div`
  display: flex;
  align-items: center;
  span:first-of-type {
    width: 50%;
  }
  span:nth-of-type(2) {
    width: 20%;
  }
`;

const AddButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

class SshKeyListField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      visibleSshKey: null,
      isNew: false
    };
  }

  componentDidMount = () => {
    const { formatMessage } = this.props.intl;
    if (this.props.me.username !== this.props.username) {
      this.setState({
        noEdit: formatMessage(
          {
            id: "no_edit_or_view"
          },
          {
            // eslint-disable-next-line react/display-name, react/no-multi-comp
            editType: formatMessage({ id: "ssh_keys" })
          }
        )
      });
      return;
    }

    this.props.loadSshKeys();

    this.setState(() => ({
      sshKeys: [...this.props.sshKeys.valueSeq().toJS()]
    }));
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.sshKeys !== this.props.sshKeys) {
      this.setState(() => ({
        sshKeys: [...nextProps.sshKeys.valueSeq().toJS()],
        isNew: false
      }));
    }
  };

  onChange = (value, field, index) => {
    this.setState(prevState => {
      const nextState = prevState;

      nextState.sshKeys[index] = {
        ...(this.state.sshKeys[index] || this.props.sshKeys[index]),
        [field]: value
      };

      return nextState;
    });
  };

  save = sshKey => {
    this.props.addSshKey({
      title: sshKey.title,
      value: sshKey.value
    });
  };

  cancel = onCancelEnd => {
    if (this.state.isNew) {
      this.setState(prevState => {
        const nextState = prevState;
        nextState.isNew = false;
        nextState.sshKeys.splice(0, 1);
        return nextState;
      }, onCancelEnd);
    }
    this.props.cancel();
  };

  delete = () => {
    this.props.delete(this.state.sshKey.key_id);
  };

  close = () => {
    this.setState({
      visibleSshKey: null
    });
  };

  view = (sshKey, sshKeyIndex) => {
    if (this.state.isNew) {
      this.cancel(() =>
        this.setState({
          visibleSshKey: sshKeyIndex - 1,
          sshKey: sshKey
        })
      );
    } else {
      this.setState({
        visibleSshKey: sshKeyIndex,
        sshKey: sshKey
      });
    }
  };

  showErrorModal = () => {
    return (
      this.state.isNew &&
      this.props.errors?.get("title")?.includes("is already in use")
    );
  };

  addNewSshKey = () => {
    if (!this.state.isNew) {
      this.setState(prevState => {
        const nextState = prevState;

        nextState.sshKeys.unshift({});
        this.props.editLine(0, true);
        nextState.visibleSshKey = null;
        nextState.isNew = true;
        return nextState;
      });
    }
  };

  render() {
    const { isLoading = true, intl } = this.props;
    if (this.state.noEdit) {
      return <Error>{this.state.noEdit}</Error>;
    }

    return (
      <SshKeyWrapper>
        <AddButtonWrapper>
          <SshKeyListHeading2>
            <FormattedMessage id="ssh_keys" />
          </SshKeyListHeading2>
          <MediaQuery queryString="(min-width: 768px)">
            {match => (
              <ButtonAdd
                id="add-new-ssh-key"
                onClick={this.addNewSshKey}
                customText={match && "Add public key"}
              />
            )}
          </MediaQuery>
        </AddButtonWrapper>
        <SshKeyListPageDescription>
          <SshKeyListInfoDialog
            text={<FormattedMessage id="ssh.add.info.learnmore.description" />}
            linkText={<FormattedMessage id="ssh.add.info.learnmore" />}
            icon={<InfoIcon />}
            align="left"
            to={intl.formatMessage({ id: "links.documentation.SSH_keys" })}
          />
          <FormattedMessage id="ssh.add.info.description" />
        </SshKeyListPageDescription>
        {this.showErrorModal() && (
          <NotificationModal
            title={<FormattedMessage id="ssh.duplicatedkey.title" />}
            message={<FormattedMessage id="ssh.duplicatedkey.message" />}
            isOpen={this.showErrorModal()}
            onConfirm={() => this.cancel()}
          />
        )}
        {isLoading && <Loading />}
        {!isLoading && this.state.sshKeys?.size === 0 && (
          <EmptyText className="no-ssh-keys-message">
            <FormattedMessage id="no_ssh_keys" />
          </EmptyText>
        )}
        {(this.state.sshKeys || this.props.sshKeys.valueSeq()).map(
          (sshKey, index) => {
            if (this.props.editedLine === index) {
              return (
                <SettingLine
                  key={`${sshKey.title}-${index}-new`}
                  info={
                    <InfoLayout>
                      <span>
                        <strong>Add SSH Key</strong>
                      </span>
                    </InfoLayout>
                  }
                  isOpen={true}
                  noToggle={true}
                  variant="mobile"
                >
                  <SshKeyForm
                    key={`${sshKey.title}-${index}-edit`}
                    title={sshKey.title}
                    value={sshKey.value}
                    onSave={this.save}
                    onCancel={this.cancel}
                    errors={this.props.errors}
                    isLoading={this.props.isUpdateLoading}
                  />
                </SettingLine>
              );
            }
            if (this.state.visibleSshKey === index) {
              return (
                <SettingLine
                  key={`${sshKey.title}-${index}-view`}
                  id={`org-sshkey-list-${sshKey.title}`}
                  info={
                    <InfoLayout>
                      <span>
                        <strong>{sshKey.title}</strong>
                      </span>
                      <span>{sshKey.id}</span>
                    </InfoLayout>
                  }
                  openText="View"
                  isOpen={true}
                  onClick={this.close}
                  variant="mobile"
                >
                  <SshKeyView
                    value={sshKey.value}
                    title={sshKey.title}
                    showDelete={
                      sshKey.hasPermission && sshKey.hasPermission("#delete")
                    }
                    sshKey={this.state.sshKey}
                    deleteFunc={this.delete}
                    intl={intl}
                  />
                </SettingLine>
              );
            }
            return (
              <div key={`${sshKey.title}-${index}-read`}>
                <SettingLine
                  id={`org-sshkey-list-${sshKey.title}`}
                  variant="mobile"
                  info={
                    <InfoLayout>
                      <span>
                        <strong>{sshKey.title}</strong>
                      </span>
                      <span>{sshKey.id}</span>
                    </InfoLayout>
                  }
                  openText="View"
                  isOpen={false}
                  onClick={() => this.view(sshKey, index)}
                />
              </div>
            );
          }
        )}
      </SshKeyWrapper>
    );
  }
}

SshKeyListField.propTypes = {
  loadSshKeys: PropTypes.func,
  onChange: PropTypes.func,
  updateSshkey: PropTypes.func,
  editLine: PropTypes.func,
  delete: PropTypes.func,
  addSshKey: PropTypes.func,
  cancel: PropTypes.func,
  project: PropTypes.object,
  sshKeys: PropTypes.object,
  editedLine: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  isLoading: PropTypes.bool,
  isUpdateLoading: PropTypes.bool,
  errors: PropTypes.object,
  intl: PropTypes.object,
  me: PropTypes.object,
  username: PropTypes.string
};

const mapStateToProps = state => {
  const sshKey = state.userSshKey || new Map();

  return {
    editedLine: sshKey.get("editedLine"),
    errors: sshKey.get("error", new Map()),
    sshKeys: sshKey.get("data", new Map()),
    isUpdateLoading: sshKey.get("updateLoading"),
    isLoading: sshKey.get("loading"),
    me: state.app.get("me")?.toJS()
  };
};

const mapDispatchToProps = dispatch => ({
  loadSshKeys: () =>
    import("Reducers/organization/settings/sshKey").then(reducer =>
      dispatch(reducer.loadSshKeys())
    ),
  editLine: index =>
    import("Reducers/organization/settings/sshKey").then(reducer =>
      dispatch(reducer.editLine({ index }))
    ),
  delete: id =>
    import("Reducers/organization/settings/sshKey").then(reducer =>
      dispatch(reducer.deleteSshKey({ id }))
    ),
  cancel: () =>
    import("Reducers/organization/settings/sshKey").then(reducer =>
      dispatch(reducer.cancelAddSshKey())
    ),
  addSshKey: sshKey =>
    import("Reducers/organization/settings/sshKey").then(reducer => {
      return dispatch(reducer.addSshKey({ sshKey }));
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SshKeyListField));
