import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Map } from "immutable";
import { useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import { LiveMessage } from "react-aria-live";

import withReducers from "Hocs/withReducers";
import useDecodedParams from "Hooks/useDecodedParams";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import { environmentSelector } from "Reducers/environment";
import {
  addVariable,
  deleteVariable,
  getVariables,
  updateVariable
} from "Reducers/environment/settings/variable";
import { getVariables as getProjectVariables } from "Reducers/project/settings/variable";

import { sortBy } from "Libs/utils";
import { encodeVariableId } from "../../../../../project/settings/variables/containers/ProjectVariables";

import ButtonAdd from "Components/ButtonAdd";

import SettingLine from "Components/SettingLine";
import Heading2 from "Components/styleguide/Heading2";
import Loading from "Components/Loading";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import ModalConfirmLeaveForm from "Components/ModalConfirmLeaveForm";
import PageDescription from "Components/PageDescription";
import PageMeta from "Components/PageMeta";

import Empty from "../../../../settings/variables/components/Empty";
import VariableForm from "../../../../../../common/components/VariableForm";
import VariableLine from "../components/VariableLine";

import * as S from "./EnvironmentVariables.styles";

const EnvironmentVariables = ({ isNew = false, url }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { environmentId, organizationId, projectId, variableId } =
    useDecodedParams();

  const [modal, setModal] = useState({ isOpen: false });
  const [isChanged, setIsChanged] = useState(false);

  const project = useSelector(({ project }) =>
    project?.getIn(["data", organizationId, projectId])
  );
  const environment = useSelectorWithUrlParams(environmentSelector);
  const envTree = useSelector(
    ({ environment }) =>
      environment
        ?.getIn(["tree", organizationId, projectId, environmentId], Map())
        .toJS().path || []
  );
  const envVariables = useSelector(
    ({ environmentVariable }) => environmentVariable || Map()
  );
  const errors = envVariables.get("errors", {});
  const status = envVariables.get("status");
  const variables = envVariables
    .getIn(["data", organizationId, projectId, environmentId], Map())
    ?.valueSeq()
    ?.toJS();

  const parentsVariables = envTree.reduce((list, env) => {
    list[env] = envVariables
      .getIn(["data", organizationId, projectId, env], Map())
      .valueSeq()
      ?.toJS();
    return list;
  }, []);

  const projectVariables = useSelector(
    ({ projectVariable }) => projectVariable || Map()
  );
  const projectListVariables = projectVariables
    ?.getIn(["data", organizationId, projectId], Map())
    ?.valueSeq()
    ?.toJS();

  const loading =
    envVariables.get("loading", true) || projectVariables.get("loading", true);

  useEffect(() => {
    if (["added", "updated", "deleted"].includes(status)) cancel();
  }, [status]);

  useEffect(() => {
    dispatch(getProjectVariables({ organizationId, projectId }));
  }, [projectId]);

  useEffect(() => {
    dispatch(getVariables({ organizationId, projectId, environmentId }));
  }, [environmentId]);

  useEffect(() => {
    envTree.forEach(env =>
      dispatch(
        getVariables({
          organizationId,
          projectId,
          environmentId: env
        })
      )
    );
  }, [envTree.length]);

  useEffect(() => {
    if (isNew || loading) return;
    if (
      !variables.find(elt => encodeVariableId(elt.id) === variableId) &&
      !projectListVariables.find(
        elt => `project-${encodeVariableId(elt.id)}` === variableId
      )
    )
      cancel();
  }, [loading, variableId]);

  const cancel = () => {
    history.replace(url);
  };

  const save = data => {
    setIsChanged(false);

    if (isNew) {
      dispatch(addVariable({ organizationId, projectId, environment, data }));
    } else {
      const variable = variables.find(elt => elt.id === data.id);
      dispatch(
        updateVariable({
          organizationId,
          projectId,
          environmentId,
          variable,
          data
        })
      );
    }
  };

  const handleCancel = () => {
    if (isChanged && !modal.isOpen) {
      setModal({ isOpen: true, type: "leave" });
      setIsChanged(true);
    } else {
      setIsChanged(false);
      cancel();
    }
  };

  const expand = id => {
    if (isNew && id === "new") return;
    if (!id || variableId === encodeVariableId(id)) {
      handleCancel();
    } else {
      if (isChanged && !modal.isOpen) {
        setModal({ isOpen: true, type: "leave", next: id });
        setIsChanged(true);
      } else {
        history.replace(
          `${url}/${
            id === "new" ? "-/new" : encodeURIComponent(encodeVariableId(id))
          }`
        );
        setIsChanged(false);
      }
    }
  };

  const isOverriddenFrom = variable => {
    const projectVar = projectListVariables.find(elt => elt.id === variable.id);
    if (projectVar) {
      return "project";
    }

    if (variable.inherited) return false;
    const parentVar = parentsVariables[environment?.parent]?.find(
      elt => elt.id === variable.id
    );
    if (
      !parentVar ||
      ![
        "value",
        "is_json",
        "is_sensitive",
        "visible_build",
        "visible_runtime",
        "is_inheritable",
        "is_enabled"
      ].some(val => parentVar[val] !== variable[val])
    )
      return false;
    return "environment";
  };

  const getOrigin = variable => {
    const origin = envTree.reverse().find(env => {
      return !parentsVariables[env].find(elt => elt.id === variable.id)
        ?.inherited;
    });
    return origin;
  };

  return (
    <div>
      <LiveMessage
        message={`${environment?.title} environment-level variable settings`}
        aria-live="polite"
      />
      <PageMeta
        title={`Variables | ${environment?.title} | ${project?.title}`}
      />

      {environment?.hasPermission &&
        environment.hasPermission("#manage-variables") &&
        (variables?.length > 0 || projectListVariables?.length > 0) && (
          <ButtonAdd
            id="add-new-variable"
            css={{ float: "right" }}
            customText={intl.formatMessage({
              id: "settings.variables.add.button"
            })}
            onClick={() => expand("new")}
          />
        )}

      <Heading2 id="settings-heading" css={{ marginBottom: "80px" }}>
        {environment?.title || intl.formatMessage({ id: "variables" })}
      </Heading2>

      <PageDescription>
        <p>
          {intl.formatMessage({
            id: "settings.environment.variables.description"
          })}
        </p>
        <S.Note>
          {intl.formatMessage(
            { id: "settings.variables.note" },
            {
              dt: (...chunks) => <dt>{chunks}</dt>, // eslint-disable-line react/display-name
              dd: (...chunks) => <dd>{chunks}</dd>, // eslint-disable-line react/display-name
              code: (...chunks) => <code>{chunks}</code> // eslint-disable-line react/display-name
            }
          )}
        </S.Note>
      </PageDescription>

      <section aria-labelledby="settings-heading">
        {loading ? (
          <Loading />
        ) : (
          <>
            {isNew && (
              <SettingLine
                id={`project-variable-new`}
                isNew={true}
                addNewTitle={intl.formatMessage({
                  id: "settings.variables.add.title"
                })}
                isOpen={true}
                onClick={expand}
              >
                <VariableForm
                  key={`var-new-form`}
                  environment={environment}
                  onSave={save}
                  onCancel={handleCancel}
                  errors={errors}
                  isLoading={status === "pending"}
                  isChanged={isChanged}
                  isChangedUpdate={() => setIsChanged(true)}
                />
              </SettingLine>
            )}

            {variables?.length === 0 &&
            projectListVariables?.length === 0 &&
            !isNew ? (
              <Empty onClick={() => expand("new")} />
            ) : (
              <>
                <S.ListTitle>
                  {intl.formatMessage(
                    { id: "settings.environment.variables.list.environment" },
                    {
                      count: <span>({variables?.length})</span>
                    }
                  )}
                </S.ListTitle>
                {variables?.length === 0 ? (
                  <S.EmptyList>
                    {intl.formatMessage(
                      {
                        id: "settings.environment.variables.list.empty"
                      },
                      {
                        // eslint-disable-next-line react/display-name
                        link: (...chunks) => (
                          <Link to={`${url}/-/new`}>{chunks}</Link>
                        )
                      }
                    )}
                  </S.EmptyList>
                ) : (
                  <>
                    {sortBy(variables, "id").map(variable => {
                      const overriddenFrom = isOverriddenFrom(variable);
                      return (
                        <VariableLine
                          key={`var-environment-${variable.id}`}
                          variable={variable}
                          isOpen={variableId === encodeVariableId(variable.id)}
                          isOverriddenFrom={overriddenFrom}
                          expand={() => expand(variable.id)}
                        >
                          {variableId === encodeVariableId(variable.id) && (
                            <VariableForm
                              key={`var-${variable.id}-form`}
                              onSave={save}
                              onCancel={handleCancel}
                              onDelete={() =>
                                setModal({
                                  isOpen: true,
                                  variable,
                                  type: "delete"
                                })
                              }
                              errors={errors}
                              isLoading={status === "pending"}
                              variable={variable}
                              environment={environment}
                              isChanged={isChanged}
                              isChangedUpdate={() => setIsChanged(true)}
                              isOverriddenFrom={overriddenFrom}
                              originEnv={
                                overriddenFrom === "environment" ||
                                variable.inherited
                                  ? getOrigin(variable)
                                  : null
                              }
                            />
                          )}
                        </VariableLine>
                      );
                    })}
                  </>
                )}

                <S.ListTitle>
                  {intl.formatMessage(
                    { id: "settings.environment.variables.list.project" },
                    {
                      count: <span>({projectListVariables?.length || 0})</span>
                    }
                  )}
                </S.ListTitle>
                {projectListVariables?.length === 0 ? (
                  <S.EmptyList>
                    {intl.formatMessage(
                      {
                        id: "settings.project.variables.list.empty"
                      },
                      {
                        // eslint-disable-next-line react/display-name
                        link: (...chunks) => (
                          <Link
                            to={`/${organizationId}/${projectId}/-/settings/variables/-/new`}
                          >
                            {chunks}
                          </Link>
                        )
                      }
                    )}
                  </S.EmptyList>
                ) : (
                  <>
                    {sortBy(projectListVariables, "id").map(variable => {
                      const inactive = variables.some(
                        envVar => envVar.id === variable.id
                      );
                      return (
                        <VariableLine
                          key={`var-project-${variable.id}`}
                          variable={variable}
                          isInactive={inactive}
                          isOpen={
                            variableId ===
                            `project-${encodeVariableId(variable.id)}`
                          }
                          openText={intl.formatMessage({ id: "view" })}
                          expand={() => expand(`project-${variable.id}`)}
                        >
                          {variableId === `project-${variable.id}` && (
                            <VariableForm
                              key={`var-project-${variable.id}-form`}
                              variable={variable}
                              editable={false}
                              isInactive={inactive}
                              linkToEdit={`/${organizationId}/${projectId}/-/settings/variables/${encodeURIComponent(
                                encodeVariableId(variable.id)
                              )}`}
                            />
                          )}
                        </VariableLine>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </>
        )}

        <ModalConfirmDelete
          isOpen={modal.isOpen && modal.type === "delete"}
          closeModal={() => setModal({ isOpen: false })}
          deleteFunction={() =>
            dispatch(
              deleteVariable({
                organizationId,
                projectId,
                environmentId,
                variable: modal.variable
              })
            )
          }
          itemType="variable"
          itemName={modal.variable?.name}
          itemId={modal.variable?.name}
        />

        <ModalConfirmLeaveForm
          isOpen={modal.isOpen && modal.type === "leave"}
          closeModal={() => setModal({ isOpen: false })}
          continueFunction={() => expand(modal.next)}
          cancelFunction={() => setModal({ isOpen: false })}
        />
      </section>
    </div>
  );
};

EnvironmentVariables.propTypes = {
  isNew: PropTypes.bool,
  url: PropTypes.string.isRequired
};

export default withReducers({
  project: () => import("Reducers/project"),
  projectVariable: () => import("Reducers/project/settings/variable"),
  environment: () => import("Reducers/environment"),
  environmentVariable: () => import("Reducers/environment/settings/variable")
})(EnvironmentVariables);
