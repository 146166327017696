import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import Button from "UI/Button";
import InfoDialog from "Components/InfoDialog";
import SuccessStateIcon from "Icons/SuccessStateIcon";
import IntegrationIcon from "../../../components/IntegrationIcon";

import * as S from "./IntegrationItem.styles";

const IntegrationItem = ({ integration, onSelect, isConnected }) => {
  const intl = useIntl();

  const { type } = integration;

  return (
    <S.Wrapper>
      <S.Header>
        <IntegrationIcon type={type} />
        <S.Name>
          {intl.formatMessage({ id: `integration.type.${type}` })}
        </S.Name>
      </S.Header>
      <S.Description>
        {intl.formatMessage({ id: `integration.type.${type}.description` })}
      </S.Description>
      <S.Footer>
        {isConnected ? (
          <S.Connected>
            <SuccessStateIcon />
            {intl.formatMessage({ id: "integration.setup.connected" })}
          </S.Connected>
        ) : (
          <Button variant="link" onClick={onSelect}>
            {intl.formatMessage({ id: "integration.setup.add" })}
          </Button>
        )}

        {integration.category === "git" && (
          <InfoDialog
            title={intl.formatMessage({
              id: "integration.setup.categories.git.info.title"
            })}
            text={intl.formatMessage({
              id: "integration.setup.categories.git.info.text"
            })}
            to={intl.formatMessage({
              id: "links.documentation.integrations.source"
            })}
            linkText={intl.formatMessage({ id: "learnmore" })}
            align="right"
          />
        )}
      </S.Footer>
    </S.Wrapper>
  );
};

IntegrationItem.propTypes = {
  integration: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  isConnected: PropTypes.bool
};

export default IntegrationItem;
