import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { Map } from "immutable";
import CustomScroll from "react-custom-scroll";

import { getRegionLabel, getSubscriptionEditUrl } from "Libs/utils";

import withReducers from "Hocs/withReducers";
import { openProjectWizard } from "Reducers/project/wizard";
import { subscriptionSelector } from "Reducers/subscription";

import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";

import { environmentsSelector } from "Reducers/environment";
import { getAccesses } from "Reducers/project/access";
import { getPlans } from "Reducers/plan";

import { Button, CodeIcon, SettingsIcon } from "@platformsh/ui-kit";

import AccessibleTooltip from "Components/AccessibleTooltip";
import Loading from "Components/Loading";
import Screenshot from "../../../../common/components/Screenshot";

import * as S from "./ProjectDetails.styles";

const getMaxDevelopementEnvironmentNumber = project => {
  if (!project || !project.subscription) {
    return "";
  }
  const env =
    project.subscription.resources?.development?.max_environments ||
    project.subscription.environments;
  if (env) return env + 1;

  return "";
};

const MAX_AVATARS = 5;

const ProjectDetails = () => {
  const { organizationId, projectId } = useParams();
  const intl = useIntl();
  const dispatch = useDispatch();

  const project = useSelector(({ project }) =>
    project?.getIn(["data", organizationId, projectId], Map())
  );
  const environments = useSelectorWithUrlParams(environmentsSelector);
  const subscription = useSelector(state => {
    return subscriptionSelector(state, {
      organizationId,
      projectId,
      id: project?.subscription_id
    });
  });
  const plan = useSelector(({ plan }) => {
    // Leave the field for the plan blank until .plan has been populated by a response from the API.
    // This ensures we don't have a "flash" of the machine name before the label is loaded from the API.
    if (plan?.has("data") === false) return " ";
    return plan?.getIn(
      ["data", subscription?.plan, "label"],
      subscription?.plan
    );
  });
  const projectAccesses = useSelector(({ projectAccess }) =>
    projectAccess?.getIn(["data", organizationId, projectId], Map())?.entrySeq()
  );

  useEffect(() => {
    dispatch(getPlans());
  }, []);

  useEffect(() => {
    if (project?.id) dispatch(getAccesses({ organizationId, project }));
  }, [project]);

  const region = useMemo(
    () => getRegionLabel(project?.region_label),
    [project]
  );
  const mainUrl = useMemo(() => {
    let url;
    if (project.default_domain) {
      url = project.default_domain;
    } else {
      url = environments?.find(env => env.id === project.default_branch)?.data
        .edge_hostname;
    }
    if (url) return `https://${url}`;
  }, [environments, project]);
  const subscriptionEditUrl = useMemo(
    () => getSubscriptionEditUrl({ project, subscription }),
    [project, subscription]
  );

  const openWizard = () => {
    dispatch(openProjectWizard({ organizationId, projectId }));
  };

  if (!project) return <Loading />;

  return (
    <S.Wrapper>
      <S.Header>
        <S.Url url={project.default_domain}>
          <Screenshot
            imagePath={`/${projectId}/${
              project?.default_branch || "master"
            }/screenshot.png`}
          />
        </S.Url>
      </S.Header>

      <S.Details>
        <S.ProjectName>
          {project.title || intl.formatMessage({ id: "no_title" })}
        </S.ProjectName>
        <S.IdRegion>
          {project.id}
          {region && `, ${region.title} ${region.suffix}`}
        </S.IdRegion>

        {!project.default_domain && (
          <S.Wizard>
            <Button variant="link" onClick={openWizard}>
              <CodeIcon />
              {intl.formatMessage({ id: "project.overview.wizard" })}
            </Button>
          </S.Wizard>
        )}

        {/* Plan */}
        <S.Plan>
          {intl.formatMessage(
            {
              id: "project.details.plan"
            },
            { name: plan }
          )}
          {subscriptionEditUrl && (
            <>
              {subscriptionEditUrl.external ? (
                <a
                  href={subscriptionEditUrl.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({
                    id: "project.details.plan.upgrade"
                  })}
                </a>
              ) : (
                <Link
                  to={{
                    pathname: subscriptionEditUrl.url,
                    state: { from: "project" }
                  }}
                >
                  {intl.formatMessage({
                    id: "project.details.plan.upgrade"
                  })}
                </Link>
              )}
            </>
          )}
        </S.Plan>

        <S.Infos displayValues={environments?.size > 0}>
          {/* Storage */}
          <S.Info>
            <S.Value aria-labelledby="projectoverview-storage">
              {project.subscription &&
                `${project.subscription.storage / 1024} GB`}
            </S.Value>
            <S.Label id="projectoverview-storage">
              {intl.formatMessage({ id: "project.details.disk" })}
            </S.Label>
          </S.Info>

          {/* Nb of environments */}
          <S.Info>
            <S.Value aria-labelledby="projectoverview-environments">
              {intl.formatMessage(
                {
                  id: "project.details.environments_used"
                },
                {
                  used: environments?.filter(env => env.status === "active")
                    .size,
                  total: getMaxDevelopementEnvironmentNumber(project)
                }
              )}
            </S.Value>
            <S.Label id="projectoverview-environments">
              {intl.formatMessage({ id: "project.details.environments" })}
            </S.Label>
          </S.Info>

          {/* Nb of Users */}
          <S.Info>
            <S.Value aria-labelledby="projectoverview-users">
              {project.subscription?.user_licenses}
            </S.Value>
            <S.Label id="projectoverview-users">
              {intl.formatMessage({ id: "project.details.users" })}
            </S.Label>
          </S.Info>

          {projectAccesses?.size > 1 && (
            <S.Users>
              {projectAccesses.slice(0, MAX_AVATARS).map(([id, acc]) => (
                <S.Avatar
                  key={`avatar-${id}`}
                  name={acc.getUser()?.display_name}
                />
              ))}

              {projectAccesses.size > MAX_AVATARS && (
                <S.UsersMore>
                  <span>+{projectAccesses.size - MAX_AVATARS}</span>
                  <S.UsersFullList>
                    <CustomScroll heightRelativeToParent="183px">
                      {projectAccesses.map(([id, acc]) => (
                        <S.User key={`user-${id}`}>
                          {acc.getUser()?.display_name}
                        </S.User>
                      ))}
                    </CustomScroll>
                  </S.UsersFullList>
                </S.UsersMore>
              )}
            </S.Users>
          )}
        </S.Infos>
      </S.Details>

      {mainUrl && (
        <S.MainUrl>
          <S.Truncate>
            <AccessibleTooltip
              tooltipProps={{
                id: "environment-url-full",
                children: mainUrl
              }}
            >
              <a href={mainUrl} rel="noopener noreferrer" target="_blank">
                {mainUrl}
              </a>
            </AccessibleTooltip>
          </S.Truncate>
          <S.DomainLink
            to={`/${organizationId}/${projectId}/-/settings/domains`}
          >
            <AccessibleTooltip
              tooltipProps={{
                id: `custom-domain`,
                children: intl.formatMessage({
                  id: `project.details.domain.${
                    project.default_domain ? "update" : "set"
                  }`
                })
              }}
            >
              <SettingsIcon />
            </AccessibleTooltip>
          </S.DomainLink>
        </S.MainUrl>
      )}
    </S.Wrapper>
  );
};

export default withReducers({
  environment: () => import("Reducers/environment"),
  plan: () => import("Reducers/plan"),
  project: () => import("Reducers/project"),
  projectAccess: () => import("Reducers/project/access"),
  projectWizard: () => import("Reducers/project/wizard"),
  region: () => import("Reducers/project/region"),
  subscription: () => import("Reducers/subscription")
})(ProjectDetails);
