import React from "react";
import PropTypes from "prop-types";

import { getDateFormat, getNowFormat } from "./utils";

import * as S from "./TimeframeSelector.styles";

const TimeFrameSelector = ({
  timeframes = [],
  selectedTimeframe,
  from,
  to,
  onChange
}) => (
  <S.Layout>
    <S.List>
      {timeframes.map(timeframe => (
        <S.ListItem key={timeframe.label}>
          <S.Toggle
            isActive={timeframe.id === selectedTimeframe.id}
            onClick={() => onChange(timeframe)}
          >
            {timeframe.label}
          </S.Toggle>
        </S.ListItem>
      ))}
    </S.List>
    <S.Toggle isActive={!selectedTimeframe?.id}>
      {selectedTimeframe.id ? getNowFormat() : getDateFormat(from, to)}
    </S.Toggle>
  </S.Layout>
);

TimeFrameSelector.propTypes = {
  onChange: PropTypes.func,
  selectedTimeframe: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
    range: PropTypes.number,
    id: PropTypes.string
  }),
  timeframes: PropTypes.array,
  from: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default React.memo(TimeFrameSelector);
