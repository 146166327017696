import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";

import formatPercentage from "../../../utils/formatPercentage";

import Portal from "./Tooltip.portal";
import * as S from "./Tooltip.styles";

const MARGIN = 20;

/**
 * @param {string} hostname
 * @returns {string} the hostname limited to 15 characters
 */
const clampHostname = hostname => {
  if (hostname.length <= 15) {
    return hostname;
  }

  const start = hostname.slice(0, 5);
  const end = hostname.slice(-7);

  return `${start}...${end}`;
};

const calculatePosition = (pointerX, pointerY, tooltip) => {
  const defaults = { width: 260, x: pointerX, height: 0 };

  const { width, x, height } = tooltip?.getBoundingClientRect() || defaults;
  const isLeft = x < pointerX;
  let overflows = false;

  if (isLeft) {
    overflows = (x + width + MARGIN) * 2 > document.body.offsetWidth;
  } else {
    overflows = x + width + MARGIN > document.body.offsetWidth;
  }

  const tooltipX = overflows ? pointerX - width - MARGIN : pointerX + MARGIN;
  const tooltipY = pointerY - Math.round(height / Math.PI);

  return { transform: `translate(${tooltipX}px, ${tooltipY}px)` };
};

const Tooltip = ({
  data,
  hosts,
  activeLine,
  tooltipFormatter,
  x,
  y,
  isVisible,
  forwardedRef
}) => {
  const tooltip = useRef();
  const rows = useMemo(() => {
    if (!hosts?.length || !data) {
      return [];
    }

    return hosts
      ?.filter(host => data[`${host}@value`] >= 0)
      ?.map(host => ({
        id: host,
        values: {
          percentage: formatPercentage(data[`${host}@percentage`]),
          value: tooltipFormatter(data[`${host}@value`]),
          host: host === "average" ? "Average" : `Host ${clampHostname(host)}`
        }
      }));
  }, [data, hosts]);

  const transform = calculatePosition(x, y, tooltip?.current);

  return (
    <Portal forwardedRef={forwardedRef}>
      <S.Layout
        isVisible={isVisible && rows.length && transform}
        style={transform}
        ref={tooltip}
      >
        <S.Table>
          <tbody>
            <S.TableRow>
              <td>{tooltipFormatter(data?.max)}</td>
              <td>Max</td>
            </S.TableRow>
            {rows.map(({ id, values }) => (
              <S.TableRow key={id} isActive={id === activeLine}>
                <td>{values.percentage}</td>
                <td>{values.value}</td>
                <td>{values.host}</td>
              </S.TableRow>
            ))}
          </tbody>
        </S.Table>
      </S.Layout>
    </Portal>
  );
};

Tooltip.propTypes = {
  data: PropTypes.object,
  hosts: PropTypes.array,
  activeLine: PropTypes.string,
  tooltipFormatter: PropTypes.func,
  x: PropTypes.number,
  y: PropTypes.number,
  isVisible: PropTypes.bool,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};

export default React.memo(Tooltip);
