import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Button } from "@platformsh/ui-kit";

import Calendar from "Icons/IconCalendar";

import {
  Grid as MetricsGrid,
  Layout as MetricsLayout
} from "../../../../metrics/containers/Service/Service.styles";
import useService from "../../../../metrics/hooks/useService";

import serviceKindSelector from "./serviceKind.selector";

import * as S from "./ServiceMetrics.styles";

const ServiceMetrics = ({ name }) => {
  const [data, isLoading, range, kind] = useService(name);
  const selector = useMemo(
    () => state => serviceKindSelector(state, name),
    [name]
  );
  const serviceKind = useSelector(selector);

  const intl = useIntl();

  const hasData = Object.keys(data).length > 0;

  if (kind === "grid" || kind === "dedication") {
    return null;
  }

  return (
    <S.Container>
      <S.Header>
        <S.Heading>
          <FormattedMessage id="metrics" />
        </S.Heading>

        <S.HeaderRight>
          <Button
            variant="link"
            as={Link}
            to={`../metrics?range=${range}hrs&service=${name}`}
          >
            <FormattedMessage id="view_metrics" />
          </Button>
          <S.Timeframe>
            <Calendar />
            <FormattedMessage
              id="metrics.pasthours"
              values={{ hours: range }}
            />
          </S.Timeframe>
        </S.HeaderRight>
      </S.Header>
      {isLoading ? (
        <S.ServiceMetricsSkeleton />
      ) : (
        <MetricsLayout>
          {!hasData ? (
            <S.EmptyMetrics>
              <FormattedMessage id="metrics.error.empty.paragraph" />{" "}
              <Button
                variant="link"
                as="a"
                target="_blank"
                href={intl.formatMessage({
                  id: "links.documentation.redeploy"
                })}
              >
                <FormattedMessage id="learnmore" />
              </Button>
            </S.EmptyMetrics>
          ) : (
            <MetricsGrid>
              {Object.entries(data).map(
                ([metricName, metrics]) =>
                  metrics?.length && (
                    <S.ServiceMetric
                      key={metricName}
                      name={metricName}
                      value={metrics[metrics.length - 1]["average@value"]}
                      max={metrics[metrics.length - 1].max}
                      percentage={
                        metrics[metrics.length - 1]["average@percentage"]
                      }
                      data={metrics.map(value => ({
                        timestamp: value.timestamp,
                        value: isNaN(value["average@value"])
                          ? 0
                          : value["average@value"]
                      }))}
                      isLoading={isLoading}
                      kind={kind}
                      serviceKind={serviceKind}
                    />
                  )
              )}
            </MetricsGrid>
          )}
        </MetricsLayout>
      )}
    </S.Container>
  );
};

ServiceMetrics.propTypes = {
  name: PropTypes.string
};

export default ServiceMetrics;
