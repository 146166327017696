import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import useInput from "Hooks/useInput";
import { capitalize } from "Libs/utils";

import Button from "UI/Button";
import Error from "Components/Error";
import Loading from "Components/Loading";

import * as S from "./DomainForm.styles";

const DomainForm = ({
  errors = {},
  isChangedUpdate,
  isFirst,
  isLoading,
  onCancel,
  onSave
}) => {
  const intl = useIntl();

  const { inputs, handleInputChange } = useInput(
    {
      name: ""
    },
    isChangedUpdate
  );

  const domainIsValid = useMemo(() => {
    return inputs.name;
  }, [inputs.name]);

  const handleSave = e => {
    e?.preventDefault();

    if (!inputs.name) return;

    onSave({
      name: inputs.name
    });
  };

  return (
    <S.Wrapper>
      <LiveMessage
        message={intl.formatMessage({ id: "add_domain" })}
        aria-live="polite"
      />

      <S.FormWrapper aria-labelledby="edit-domain-heading">
        {errors.error && <Error>{errors.error}</Error>}

        <S.InputWrapper>
          <S.Input
            id="name"
            label={intl.formatMessage({ id: "settings.domains.name" })}
            placeholder={intl.formatMessage({
              id: "settings.domains.placeholder"
            })}
            value={inputs.name}
            onChange={elt => handleInputChange(elt)}
            error={errors["name"]}
          />
        </S.InputWrapper>

        <S.Note>
          {intl.formatMessage(
            { id: "settings.domains.traffic" },
            {
              code: (...chunks) => <code>{chunks}</code>, // eslint-disable-line react/display-name
              dt: (...chunks) => <dt>{chunks}</dt>, // eslint-disable-line react/display-name
              dd: (...chunks) => <dd>{chunks}</dd> // eslint-disable-line react/display-name
            }
          )}
        </S.Note>

        <S.ButtonWrapper>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Button
                id="save-domain-btn"
                type="submit"
                aria-label={intl.formatMessage({
                  id: "add_domain"
                })}
                onClick={handleSave}
                disabled={!domainIsValid}
              >
                {capitalize(
                  intl.formatMessage({
                    id: "add_domain"
                  })
                )}
              </Button>

              {!isFirst && (
                <Button
                  id="cancel-domain-btn"
                  type="button"
                  variant="secondary"
                  aria-label={intl.formatMessage({ id: "cancel" })}
                  onClick={onCancel}
                >
                  {capitalize(intl.formatMessage({ id: "cancel" }))}
                </Button>
              )}
            </>
          )}
        </S.ButtonWrapper>
      </S.FormWrapper>
    </S.Wrapper>
  );
};

DomainForm.propTypes = {
  errors: PropTypes.object,
  isChangedUpdate: PropTypes.func,
  isFirst: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

export default DomainForm;
