import styled from "styled-components";

import { css, HEADER, SECTION, getCSSVarString } from "Libs/themes";

import InlineBanner from "Components/InlineBanner";

export const Wrapper = styled(InlineBanner)`
  margin-bottom: 0;
  padding: 24px;
  border-radius: 2px;
`;

export const Header = styled.header`
  margin: 0 0 16px 0;
`;

export const Remaining = styled.div`
  display: flex;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
`;

export const Credit = styled.span`
  margin-left: auto;
  font-weight: 600;
`;

export const Expiration = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: var(--section-slate-color,var(--section-slate,var(--slate))) !important;
`;

export const Content = styled.div`
  p {
    margin: 0;
    font-size: 15px;
    line-height: 24px;
    color:var(--header-ebony-color,var(--header-ebony,var(--ebony)));
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
