import styled from "styled-components";

import InlineBanner from "Components/InlineBanner/InlineBanner";
import { breakpoints } from "Libs/theme";
import Button from "Components/Button";

export const HeaderWrapper = styled.div`
  padding-top: 25px;
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  h2 {
    margin-top: ${props => (props.hasBackButton ? "0" : "15px")};
  }
  @media (max-width: ${breakpoints[1]}) {
    h2 {
      font-size: 18px;
      margin-top: 0;
      margin-bottom: 9px;
    }
  }
`;

export const Banner = styled(InlineBanner)`
  display: flex;
  padding: 12px 16px 12px 32px;
  p {
    flex: 1;
    margin: 5px 10px 0 0;
  }
`;

export const DeleteButton = styled(Button)`
  margin-right: auto;
`;
